import React, { useState, useEffect } from 'react';
import './editMyBump.css';
import checkmarkIcon from '../../assets/images/dark-checkmark.png';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'; // Import necessary Firebase Firestore functions
import { db } from '../../firebase';

// FUNCTIONS
import { reprocessTranscript } from '../reprocessTranscript';

const ProgressBarMyBump = ({ videoId, user, videoTitle, videoQuality, videoDuration, isTranscriptReady, isProcessedbyAI, isYouTubePosted, isTranscriptEmpty }) => {
    const [transcriptError, setTranscriptError] = useState(false);

    useEffect(() => {
        if (user && user.uid) {
            const userDocRef = doc(db, 'users', user.uid);
            const videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);

            const unsubscribe = onSnapshot(videoDocRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    const videoData = docSnapshot.data();
                    // Update transcriptError state based on the document data
                    setTranscriptError(!!videoData.is_transcript_error);
                }
            }, (error) => {
                // console.error("Error fetching video metadata in onSnapshot: ", error);
            });

            // Cleanup the onSnapshot listener when the component unmounts
            return () => unsubscribe();
        }
    }, [user, videoId]);

    const handleReprocess = async () => {
        const payload = {
            user: user,
            generatedVideoId: videoId,
            videoTitle: videoTitle,
            videoQuality: videoQuality,
            videoDuration: videoDuration,
            isReprocess: true  // Add the isReprocess flag set to true
        };
    

        // Set transcriptError to false locally to update UI
        setTranscriptError(false);

        // Update Firestore to mark is_transcript_error as false
        const userDocRef = doc(db, 'users', user.uid);
        const videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);

        try {
            await updateDoc(videoDocRef, { is_transcript_error: false });

            // Call the reprocessTranscript function
            await reprocessTranscript(payload);

            // console.log('Reprocessing triggered successfully.');
        } catch (error) {
            // console.error('Error updating transcript error state or triggering reprocess:', error);
        }
    };

    return (
        <div className="valueBump-progress-bar">
            <div className="valueBump-progress-step valueBump-completed">
                <div className="valueBump-progress-label">Uploaded</div>
                <div className="valueBump-progress-circle-wrapper">
                    <div className="valueBump-progress-circle valueBump-completed">
                        <img src={checkmarkIcon} alt="Completed" />
                    </div>
                    <div className="valueBump-progress-line" style={{ backgroundColor: isTranscriptReady || isProcessedbyAI ? '#fff' : '#555', width: isTranscriptReady || isProcessedbyAI ? '100%' : '50%' }}></div>
                </div>
            </div>
            <div className="valueBump-progress-step" style={{ color: isTranscriptEmpty ? '#fff' : isTranscriptReady ? '#fff' : '#555' }}>
                    <div className="valueBump-progress-label">
                        {isTranscriptEmpty ? "No Audio" : "Analyzing"}
                    </div>
                    <div className="valueBump-progress-circle-wrapper">
                        {!transcriptError && (
                        <div className={`valueBump-progress-circle ${isTranscriptEmpty ? '' : (isTranscriptReady ? 'valueBump-completed' : 'pulsating')}`}>
                            {isTranscriptReady && !isTranscriptEmpty && <img src={checkmarkIcon} alt="Completed" />}
                        </div>
                        )}
                        {transcriptError && (
                        <button className="transcript-reprocess-button" onClick={handleReprocess}>
                            Reprocess
                        </button>
                        )}
                        <div
                        className="valueBump-progress-line"
                        style={{
                            backgroundColor: isProcessedbyAI ? '#fff' : isTranscriptReady ? '#777' : '#555',
                            width: isProcessedbyAI ? '100%' : isTranscriptReady ? '50%' : '0%'
                        }}
                        ></div>
                    </div>
                    {isTranscriptEmpty && (
                        <div className="valueBump-learn-more" style={{ marginTop: '8px' }}>
                        <a
                            href="https://intercom.help/bumpups/en/articles/8835886-what-are-the-video-requirements"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="valueBump-learn-more-link"
                        >
                            learn more
                        </a>
                        </div>
                    )}
                    </div>
            <div className="valueBump-progress-step" style={{ color: isProcessedbyAI ? '#fff' : '#555' }}>
                <div className="valueBump-progress-label">Bump AI</div>
                <div className="valueBump-progress-circle-wrapper">
                    <div className={`valueBump-progress-circle ${isProcessedbyAI ? 'valueBump-completed' : isTranscriptReady ? 'pulsating' : ''}`}>
                        {isProcessedbyAI && <img src={checkmarkIcon} alt="Completed" />}
                    </div>
                    <div className="valueBump-progress-line" style={{ backgroundColor: isYouTubePosted ? '#fff' : isProcessedbyAI ? '#777' : '#555', width: isYouTubePosted ? '100%' : isProcessedbyAI ? '50%' : '0%' }}></div>
                </div>
            </div>
            <div className="valueBump-progress-step" style={{ color: isYouTubePosted ? '#fff' : '#555' }}>
                <div className="valueBump-progress-label">Visibility</div>
                <div className="valueBump-progress-circle-wrapper">
                    <div className={`valueBump-progress-circle ${isYouTubePosted ? 'valueBump-completed' : ''}`}>
                        {isYouTubePosted && <img src={checkmarkIcon} alt="Completed" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressBarMyBump;
