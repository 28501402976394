// src/components/ListViewWorkspace/listviewWorkspace.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './listviewWorkspace.css';
import useResponsiveDesign from '../components/useResponsiveDesign'; // Adjust the path as necessary

// FIREBASE
import { collection, onSnapshot, doc, setDoc } from 'firebase/firestore';
import { storage, db, auth } from '../firebase'; // Ensure storage is exported from your firebase config
import { ref, getDownloadURL } from 'firebase/storage';

// IMPORT UI
import ProcessingPage from '../unAuthPages/ProcessingPage';

// IMAGES
import noAccessImageActive from '../assets/images/thumbnailPlacehlder-active.jpg';
import viewVideoValue from '../assets/images/v2-settings.png';
import draftIcon from '../assets/images/workspace-draft-v1.png';
import activeIcon from '../assets/images/comment-v2.png';
import workspaceAddlogo from '../assets/images/comment-v2.png';
import uploadingIcon from '../assets/images/upload-white-v1.png'; 
import errorIcon from '../assets/images/v1-warning.png'; 


const ListViewWorkspace = () => {
  const [videos, setVideos] = useState([]);
  const [isContentAvailable, setIsContentAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // State for pagination
  const [draftCount, setDraftCount] = useState(0); // New state for draft_count
  const videosPerPage = 15; // Videos per page
  const navigate = useNavigate();
  const device = useResponsiveDesign(); // Use the custom hook

  // Helper functions to generate localStorage keys
  const getLocalDraftCountKey = (userId) => `draftCount_${userId}`;
  const getLocalVideoCacheKey = (userId) => `videoCache_${userId}_WorkspaceVideos`;

  const checkFirestore = useCallback((userId) => {
    const localDraftCountKey = getLocalDraftCountKey(userId);
    const localVideoCacheKey = getLocalVideoCacheKey(userId);

    // Load draftCount from localStorage if available
    const cachedDraftCount = localStorage.getItem(localDraftCountKey);
    if (cachedDraftCount !== null) {
      setDraftCount(parseInt(cachedDraftCount, 10));
    }

    const userVideosRef = collection(db, "users", userId, "workspaceContent");
    const userSettingsRef = doc(db, "users", userId, "userSettings", "workspace");

    // Listener for workspaceContent
    const unsubscribeWorkspaceContent = onSnapshot(
      userVideosRef,
      async (snapshot) => { // Make the callback async
        // console.log("Snapshot received:", snapshot); // Log the snapshot data
    
        if (!snapshot.empty) {
          const videosFromFirestore = [];
          const thumbnailPromises = [];
    
          snapshot.forEach((doc) => {
            const videoData = doc.data();
            const videoId = doc.id.replace("workspace-", ""); // Remove the 'workspace-' prefix if applicable
            videoData.id = videoId;
    
            // console.log(`Processing video ID: ${videoId}`, videoData); // Log each video data

            // Check if video_thumbnail needs to be updated
            if (videoData.video_thumbnail && videoData.video_thumbnail.startsWith(`bumpThumbnails/`)) {
              const valueDataRef = doc.ref; // Reference to the current document
              
              const thumbnailPromise = (async () => {
                // console.log(`Fetching thumbnail for video ID ${videoId}: ${videoData.video_thumbnail}`);
                
                const thumbnailStorageRef = ref(storage, `${videoData.video_thumbnail}`);
                try {
                  const downloadUrl = await getDownloadURL(thumbnailStorageRef);
                  // console.log(`Thumbnail URL fetched for video ID ${videoId}: ${downloadUrl}`);
    
                  // Update the Firestore document with the new URL
                  await setDoc(valueDataRef, { video_thumbnail: downloadUrl }, { merge: true });
                  // console.log(`Firestore updated for video ID ${videoId} with new thumbnail URL.`);
    
                  // Update the videoData with the new URL
                  videoData.video_thumbnail = downloadUrl;
                } catch (error) {
                  // console.error(`Error fetching download URL for video ${videoId}:`, error);
                  videoData.video_thumbnail = noAccessImageActive; // Optionally, set a default thumbnail or handle the error
                }
              })();
    
              thumbnailPromises.push(thumbnailPromise);
            }
    
            videosFromFirestore.push(videoData);
          });
    
          // Wait for all thumbnail updates to complete
          await Promise.all(thumbnailPromises);
    
          // console.log("All thumbnails processed. Videos from Firestore:", videosFromFirestore);
    
          // Sort videos by workspace_creation_time in descending order
          videosFromFirestore.sort(
            (a, b) => b.workspace_creation_time.seconds - a.workspace_creation_time.seconds
          );
    
          setVideos(videosFromFirestore);
          localStorage.setItem(localVideoCacheKey, JSON.stringify(videosFromFirestore));
          setIsContentAvailable(true);
        } else {
          // console.log("No content available in snapshot.");
          setVideos([]);
          localStorage.removeItem(localVideoCacheKey);
          setIsContentAvailable(false);
        }
        setIsLoading(false);
      },
      (error) => {
        // console.error("Error fetching data from Firestore:", error);
        setIsLoading(false);
      }
    );
    

    // Listener for userSettings/workspace to get draft_count
    const unsubscribeUserSettings = onSnapshot(
      userSettingsRef,
      (settingsDoc) => {
        if (settingsDoc.exists()) {
          const data = settingsDoc.data();
          const count = data.draft_count !== undefined ? data.draft_count : 0;
          setDraftCount(count);
          localStorage.setItem(localDraftCountKey, count); // Save to localStorage
        } else {
          setDraftCount(0);
          localStorage.setItem(localDraftCountKey, 0); // Save to localStorage
        }
      },
      (error) => {
        // console.error("Error fetching userSettings/workspace:", error);
      }
    );

    // Return a cleanup function to unsubscribe both listeners
    return () => {
      unsubscribeWorkspaceContent();
      unsubscribeUserSettings();
    };
  }, []); // Add storage as a dependency if necessary

  useEffect(() => {
    const checkDataAvailability = () => {
      setIsLoading(true);
      const user = auth.currentUser;

      if (user) {
        const localStorageKey = getLocalVideoCacheKey(user.uid);
        const cachedVideos = localStorage.getItem(localStorageKey);

        if (cachedVideos) {
          // console.log("Loading workspaces from local storage.");
          setVideos(JSON.parse(cachedVideos));
          setIsContentAvailable(true);
          setIsLoading(false);
        }

        // Load draftCount from localStorage
        const localDraftCountKey = getLocalDraftCountKey(user.uid);
        const cachedDraftCount = localStorage.getItem(localDraftCountKey);
        if (cachedDraftCount !== null) {
          setDraftCount(parseInt(cachedDraftCount, 10));
        }

        const unsubscribe = checkFirestore(user.uid);
        // Cleanup listeners on unmount
        return () => {
          if (unsubscribe) unsubscribe();
        };
      } else {
        setIsLoading(false);
      }
    };

    const cleanup = checkDataAvailability();
    return cleanup;
  }, [checkFirestore]);

  const handleVideoClick = (videoId) => {
    navigate(`/workspace/${videoId}`);
  };

  const handleCreateWorkspace = () => {
    // Navigate to the New Workspace page
    navigate('/workspace/new'); // Updated route
  };

  const formatDate = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return "";
    const date = new Date(timestamp.seconds * 1000);
    const formattedDate =
      date.toLocaleString('default', { month: 'long' }) +
      ' ' +
      date.getDate() +
      ', ' +
      date.getFullYear();
    return formattedDate;
  };

  const formatDuration = (seconds) => {
    if (seconds === null || seconds === undefined || isNaN(seconds)) return "--";
    const totalSeconds = Math.floor(seconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    return hours > 0
      ? `${hours}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`
      : `${minutes}:${secs.toString().padStart(2, '0')}`;
  };

  const truncateText = (text, maxLength) => {
    if (!text || text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  // Pagination logic
  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);
  const totalPages = Math.ceil(videos.length / videosPerPage);
  const startVideoNumber = indexOfFirstVideo + 1;
  const endVideoNumber = Math.min(indexOfLastVideo, videos.length);

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  return (
    <div className="listworkspace-video-container">
      <div className="listworkspace-header-row">
        {/* Only show Workspaces header on all devices */}
        <div className="listworkspace-header-item">
            Workspaces
            {/* Add Button for Desktop and Tablet */}
            {(device === 'tablet' || device === 'desktop') && (
                <button
                className="workspace-add-button"
                onClick={handleCreateWorkspace}
                disabled={draftCount >= 10}
                aria-label={
                  draftCount >= 10
                    ? 'Maximum number of drafts reached'
                    : 'Create a new workspace'
                }
              >
                <img
                  src={workspaceAddlogo}
                  alt="Add Workspace"
                  className="workspace-add-icon"
                />
              </button>

            )}
          </div>
  
        {/* Show Status on tablet/desktop, but Duration only on desktop */}
        {(device === 'tablet' || device === 'desktop') && (
          <>
            <div className="listworkspace-header-item">Status</div>
            {device === 'desktop' && (
              <div className="listworkspace-header-item">Duration</div>
            )}
          </>
        )}
  
        <div className="create-workspace-button-container">
          <button
            className="create-workspace-button"
            onClick={handleCreateWorkspace}
            aria-label={
              draftCount >= 10
                ? device === 'mobile'
                  ? "Max drafts reached"
                  : "Maximum number of drafts reached"
                : "Create a new workspace"
            }
            disabled={draftCount >= 10}
          >
            {draftCount >= 10
              ? device === 'mobile'
                ? "Max"
                : "Max Drafts [10]"
              : device === 'mobile'
              ? "+ Create"
              : "+ Workspace"}
          </button>
        </div>
      </div>
  
      {isLoading ? (
        <div>
          <ProcessingPage />
        </div>
      ) : isContentAvailable ? (
        <>
          {currentVideos.map((video, index) => (
            <div
              key={video.id}
              className="listworkspace-video-row"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div className="listworkspace-video-item">
                <img
                  src={video.video_thumbnail || noAccessImageActive}
                  alt="thumbnail"
                  className="listworkspace-thumbnail"
                  onClick={() => handleVideoClick(video.id)}
                />
                <div className="listworkspace-title-button-wrapper">
                  <div
                    className="listworkspace-video-title clickable-title"
                    onClick={() => handleVideoClick(video.id)}
                  >
                    {truncateText(
                      video.workspace_title || video.video_file_name,
                      device === 'mobile' ? 10 : device === 'tablet' ? 40 : 54
                    )}
                  </div>
                  <div className="listworkspace-description-button-wrapper">
                    {hoveredIndex === index ? (
                      <button
                        className="view-video-button"
                        onClick={() => handleVideoClick(video.id)}
                      >
                        <img src={viewVideoValue} alt="View Video" className="view-video-icon" />
                      </button>
                    ) : (
                      <div className="listworkspace-video-description">
                        {formatDate(video.workspace_creation_time)}
                      </div>
                    )}
                  </div>
                </div>
              </div>
  
              <div className="listworkspace-video-status">
                    {(video.is_transcript_available || video.is_local_transcript_available) ? (
                      <div className="listwork-active-badge">
                        <img src={activeIcon} alt="Active Icon" className="active-icon-workspace" />
                        <span className="active-text">Active</span>
                      </div>
                    ) : video.is_caption_processing ? (
                      <div className="badge badge-uploading">
                        <img src={uploadingIcon} alt="Uploading Icon" className="draft-icon" />
                        <span className="draft-text">Uploading</span>
                      </div>
                    ) : (video.is_local_caption_processing && video.is_transcript_empty) ? (
                      <div className="badge badge-error">
                        <img src={errorIcon} alt="No Audio Icon" className="draft-icon" />
                        <span className="draft-text">No Audio</span>
                      </div>
                    ) : video.is_local_caption_processing && !video.is_transcript_empty ? (
                      <div className="badge badge-uploading">
                        <img src={uploadingIcon} alt="Processing Icon" className="draft-icon" />
                        <span className="draft-text">Processing</span>
                      </div>
                    ) : video.is_transcript_error ? (
                      <div className="error-badge">
                        <img src={errorIcon} alt="Failed Icon" className="draft-icon" />
                        <span className="draft-text">Failed</span>
                      </div>
                    ) : (
                      <div className="draft-badge">
                        <img src={draftIcon} alt="Draft Icon" className="draft-icon" />
                        <span className="draft-text">Draft</span>
                      </div>
                    )}
                  </div>

              {device === 'desktop' && (
                <div className="listworkspace-video-duration">
                  {formatDuration(video.video_duration_in_seconds)}
                </div>
              )}
            </div>
          ))}
          <div className="listworkspace-pagination-controls">
            <span className="listworkspace-pagination-info">
              {startVideoNumber}-{endVideoNumber} of about {videos.length}
            </span>
            <button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
              className="listworkspace-pagination-button"
            >
              <i className="listworkspace-outside-arrow listworkspace-double-left"></i>
            </button>
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className="listworkspace-pagination-button"
            >
              <i className="listworkspace-arrow listworkspace-left"></i>
            </button>
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className="listworkspace-pagination-button"
            >
              <i className="listworkspace-arrow listworkspace-right"></i>
            </button>
            <button
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
              className="listworkspace-pagination-button"
            >
              <i className="listworkspace-outside-arrow listworkspace-double-right"></i>
            </button>
          </div>
        </>
      ) : (
        <div className="no-content-wrapper">
          <img src={noAccessImageActive} alt="No content" className="no-content-image" />
          <div className="no-content-message">No workspaces available</div>
        </div>
      )}
    </div>
  );
};

export default ListViewWorkspace;
