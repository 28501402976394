import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 
import './generalDashboard.css';

//FUNCTIONS
import AppTransition from '../components/AppTransition';

//IMPORT UI
import Sidebar from './Sidebar';
import CloseSidebar from './CloseSidebar';

//IMAGES
import langSettings from '../assets/images/lang-swtich.png';
import brandLogo from '../assets/images/process-v1.png';
import timeLogo from '../assets/images/clock-v2.png';
import newUserachievement from '../assets/images/newuser.jpg';

import bumpupsLogo from '../assets/images/bumpups-dark-small.jpg';


import guideImage from '../assets/images/workspace-topfold.png';


function GeneralDashboard({ isSidebarOpen, toggleSidebar }) {




    return (
        <div className="dashboard">
                <Helmet>
        <title>Bumpups</title>
      </Helmet>

        {isSidebarOpen && <Sidebar />}
        {!isSidebarOpen && <CloseSidebar />}
        <AppTransition>
          <div className="dashboard-main">
            <div className="dashboard-content-wrapper">
              <div className="dashboard-contentgeneral">
                <Guides /> 
              </div>
              <div className="dashboard-right">
                <FeatureRequests />
                <Suggestions />
                <Achievement />
              </div>
            </div>
          </div>
          </AppTransition>
        </div>
        
      );
      
      
}


function Achievement() {
  const achievement = {
    title: "New Bumper!",
    description: "Congrats • Looks like you have joined the bumpups platform"
  };

  // State for controlling the popup
  const [isTrailerPopupOpen, setIsTrailerPopupOpen] = useState(false);

  // Function to open the trailer popup
  const openTrailerPopup = () => {
    setIsTrailerPopupOpen(true);
  };

  // Function to close the trailer popup by clicking on the overlay
  const closeTrailerPopup = (e) => {
    if (e.target.className === 'trailer-popup-overlay') {
      setIsTrailerPopupOpen(false);
    }
  };

  // Disable scroll when popup is open
  useEffect(() => {
    if (isTrailerPopupOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto'; // Clean up to restore scroll
    };
  }, [isTrailerPopupOpen]);

  return (
    <div className="achievement-box">
      <h3>New achievement</h3>
      <div className="achievement-content">
        <img src={newUserachievement} alt="Achievement" className="achievement-icon" />
        <div>
          <p className="achievement-title"><strong>{achievement.title}</strong></p>
          <p className="achievement-description">{achievement.description}</p>
        </div>
      </div>
      <button className="view1mindemo-btn" onClick={openTrailerPopup}>VIEW 9 MIN DEMO</button>

      {/* Trailer Popup Logic */}
      {isTrailerPopupOpen && (
        <div className="trailer-popup-overlay" onClick={closeTrailerPopup}>
          <div className="trailer-popup-content">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Vevu1P5XGNk"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="trailer-video-player"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}

function FeatureRequests() {
  const achievement = {
    title: "Driven by the Community",
    description: "Share your ideas, explore updates, and stay informed about our upcoming plans."
  };

  return (
    <div className="achievement-box">
      <h3>Feature Requests</h3>
      <div className="achievement-content">
        <img src={bumpupsLogo} alt="Feature Requests" className="achievement-icon" />
        <div>
          <p className="achievement-title"><strong>{achievement.title}</strong></p>
          <p className="achievement-description">{achievement.description}</p>
        </div>
      </div>
      <a
        href="https://bumpups.canny.io/feature-requests"
        target="_blank"
        rel="noopener noreferrer"
        className="requestfeature-btn"
      >
        Request Feature
      </a>
    </div>
  );
}



  
  const Suggestions = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 2; // Assuming there are 3 pages
  
    // Updated content for different pages with associated links
    const pageContents = [
      {
        title: "Your processing time",
        description: "Processing time on Bumpups is deducted per video, with beneficial rounding up.",
        link: "https://intercom.help/bumpups/en/articles/8858577-how-are-credits-deducted", // Replace with actual URL
        image: timeLogo
      },
      {
        title: "How do I chat with Bump AI?",
        description: "Quick guide to creating effective prompts for Bump AI responses.",
        link: "https://intercom.help/bumpups/en/articles/10035173-how-do-i-chat-with-bump-ai",
        image: brandLogo
      },
    ];
  
    const nextPage = () => {
      setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
    };
  
    const prevPage = () => {
      setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };
  
    const openLink = () => {
      const { link } = pageContents[currentPage - 1];
      window.open(link, '_blank');
    };
  
    // Get the content for the current page
    const { title, description, image } = pageContents[currentPage - 1];
  
    return (
      <div className="suggestions-container">
      <div className="suggestions-card">
        <div className="suggestions-content">
          <h3>Suggestions for you</h3>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className="suggestions-icon">
          <img src={image} alt={title} /> {/* Use dynamic image and alt text */}
        </div>
      </div>
        <div className="suggestions-actions">
          <button className="suggestions-start-btn" onClick={openLink}>READ NOW</button>
          <div className="suggestions-pagination">
            <span className="pagination-arrowbox" onClick={prevPage}>&lt;</span>
            {`${currentPage}/${totalPages}`}
            <span className="pagination-arrowbox" onClick={nextPage}>&gt;</span>
          </div>
        </div>
      </div>
    );
  };

  
  

  const Guides = () => {

    const navigate = useNavigate(); // Initialize useNavigate hook


    const handleLearnMoreClick = () => {
      // Navigate to the internal /plans route in the same tab
      navigate('/workspace');
    };
    return (
      
      <div className="guides-wrapper">
          <div className="guides-container">
              <h3 className="guides-title">New Update</h3>
              <div className="guides-thumbnail">
                  <img src={guideImage} alt="Guide Thumbnail" onClick={handleLearnMoreClick} />
              </div>
              <div className="guides-content">
                  <h2>Chat With Any YouTube Video</h2>
                  <p>Upload any video and interact directly with its content. Ask questions, request summaries, analyses, and more.</p>
                  {/* <button className="guides-action-btn">WATCH ON YOUTUBE</button> */}
                  <button className="guides-action-btn" onClick={handleLearnMoreClick}>VIEW WORKSPACES</button>
              </div>
          </div>
  
          <div className="guides-container">
              {/* <h3 className="guides-title">New update</h3> */}
              <div className="language-settingdash">
                  <img src={langSettings} alt="Guide Thumbnail" />
              </div>
              <div className="guides-content">
                  <h2>Set Your Preferred Language</h2>
                  <p>Choose your language to customize how video outputs like timestamps and descriptions are displayed.</p>
                  <Link to="/settings" className="guides-action-btn no-underline">
                      CHANGE LANGUAGE
                  </Link>        
              </div>
          </div>
      </div>
  );
  
  };



export default GeneralDashboard;