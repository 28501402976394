// src/components/UserPaidPopup.js

import React, { useState, useEffect, useRef } from 'react';
import './UserPlanPopup.css';
import basePlanLogo from '../assets/images/baseplan-white.png';
import bumpPlanLogo from '../assets/images/bumpups-white.png';
import noAccessImageActive from '../assets/images/workspace-topfold.png';
import checkmarkIcon from '../assets/images/checkmark-v1.png';

// FIREBASE
import { getFunctions, httpsCallable } from "firebase/functions";

// STRIPE
import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe outside the component to ensure it's loaded only once
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

// Pricing details for Monthly and adjusted One-Time subscriptions
const pricingDetails = {
  Base: {
    billingInfoMonthly: "For YouTube Links",
    features: [
      "YouTube video processing",
      "Prompt workspace",
      "3h 30m video length",
      "Custom brand template",
      "Bump AI video optimization",
    ],
    originalPer100: 5.00,      // Original one-time rate per 100 credits
    discountedPer100: 3.99,    // Fixed discounted monthly rate per 100 credits
    logo: basePlanLogo,
  },
  Bump: {
    billingInfoMonthly: "For Local & YouTube Links",
    features: [
      "Everything in Base, plus:",
      "Local video processing",
      "32GB video size",
      "Aspect ratios (9:16, 1:1, 16:9)",
      "Social media scheduler",
    ],
    originalPer100: 10.00,     // Original one-time rate per 100 credits
    discountedPer100: 7.99,    // Fixed discounted monthly rate per 100 credits
    logo: bumpPlanLogo,
  },
};

// Amount options for both Monthly and One-Time purchases
const amountOptions = [100, 200, 400, 600, 800, 1000];

const calculatePlanPrice = (plan, amount, paymentType) => {
  const ratePer100 =
    paymentType === 'monthly'
      ? pricingDetails[plan].discountedPer100
      : pricingDetails[plan].originalPer100;
  const price = (amount / 100) * ratePer100;
  return price.toFixed(2);
};

const UserPaidPopup = ({ onClose }) => {
  const [paymentType, setPaymentType] = useState('monthly');
  const [selectedPlan, setSelectedPlan] = useState('Base');
  const [selectedAmount, setSelectedAmount] = useState(amountOptions[0]);
  const [stripeButtonText, setStripeButtonText] = useState(paymentType === 'monthly' ? "Purchase Credits" : "Proceed to Stripe");

  // States for Monthly Payment Handling
  const [isLoadingMonthly, setIsLoadingMonthly] = useState(false);
  const [isSuccessMonthly, setIsSuccessMonthly] = useState(false);
  const [isConfirmingMonthly, setIsConfirmingMonthly] = useState(false);
  const confirmTimeoutRefMonthly = useRef(null);

  // States for One-Time Payment Handling
  const [isLoadingOneTime, setIsLoadingOneTime] = useState(false);
  const [isSuccessOneTime] = useState(false);

  useEffect(() => {
    // Reset stripeButtonText based on paymentType
    setStripeButtonText(paymentType === 'monthly' ? "Purchase Credits" : "Proceed to Stripe");
  }, [paymentType, selectedPlan]);

  useEffect(() => {
    // Clean up the timeout when the component unmounts
    return () => {
      if (confirmTimeoutRefMonthly.current) {
        clearTimeout(confirmTimeoutRefMonthly.current);
      }
    };
  }, []);

  const handlePaymentTypeChange = (type) => {
    setPaymentType(type);
  };

  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
  };

  const handleAmountChange = (amount) => {
    setSelectedAmount(amount);
  };

  // Function to handle Monthly Checkout Session
  const startMonthlyCheckoutSession = async () => {
    // Prevent multiple clicks if already loading or in success state
    if (isLoadingMonthly || isSuccessMonthly) return;

    setIsLoadingMonthly(true);
    setStripeButtonText("Processing...");

    try {
      const functions = getFunctions();
      const processCheckout = httpsCallable(functions, 'updateStripeSubscription');

      // Retrieve gclid from localStorage
      const gclid = localStorage.getItem('gclid') || '';

      // Include gclid in the payload sent to the backend
      const payload = {
        selectedMinutes: selectedAmount,
        planType: selectedPlan, // Keep it as "Base" or "Bump" without converting to lowercase
        gclid: gclid, // Include gclid if it exists
      };

      const response = await processCheckout(payload);

      // Assuming the backend returns an object with a 'success' property
      if (response.data !== undefined && response.data !== null) {
        setIsSuccessMonthly(true);
        setStripeButtonText("Credits Added 🎉");

        // After 5 seconds, reset the button to its initial state
        setTimeout(() => {
          setIsSuccessMonthly(false);
          setStripeButtonText("Purchase Credits");
        }, 5000);
      } else {
        // Instead of throwing an error, set the button text to "Retry"
        setStripeButtonText("Retry");
      }
    } catch (error) {
      // console.error("Monthly Checkout Error:", error);
      setStripeButtonText("Retry");
    } finally {
      setIsLoadingMonthly(false);
    }
  };

  // Function to handle One-Time Checkout Session
  const startOneTimeCheckoutSession = async () => {
    // Prevent multiple clicks if already loading or in success state
    if (isLoadingOneTime || isSuccessOneTime) return;

    setIsLoadingOneTime(true);
    setStripeButtonText("Loading...");

    try {
      const functions = getFunctions();
      const processCheckout = httpsCallable(functions, 'processCheckout');

      // Retrieve gclid from localStorage
      const gclid = localStorage.getItem('gclid') || '';

      // Prepare payload
      const payload = {
        selectedMinutes: selectedAmount,
        planType: selectedPlan.charAt(0).toUpperCase() + selectedPlan.slice(1), // Capitalize first letter
        gclid: gclid,
        purchaseType: 'one_time', // Set purchase type to 'one_time'
      };

      // Call the Firebase function with the prepared payload
      const response = await processCheckout(payload);

      const { id } = response.data;
      const stripe = await stripePromise;

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({ sessionId: id });

      if (error) {
        // Handle Stripe redirection errors
        setStripeButtonText("Retry");
      } else {
        // Reset button text if redirection is successful
        setStripeButtonText("Proceed to Stripe");
      }
    } catch (error) {
      // console.error("One-Time Checkout Error:", error);
      setStripeButtonText("Retry");
    } finally {
      setIsLoadingOneTime(false);
    }
  };

  // Function to handle Monthly Checkout Click with Confirmation
  const handleMonthlyCheckoutClick = () => {
    // Prevent action if loading or in success state
    if (isLoadingMonthly || isSuccessMonthly) return;

    if (!isConfirmingMonthly) {
      // Enter confirmation state
      setIsConfirmingMonthly(true);
      setStripeButtonText("Confirm?");

      // Set a timeout to revert back to initial state after 5 seconds
      confirmTimeoutRefMonthly.current = setTimeout(() => {
        setIsConfirmingMonthly(false);
        setStripeButtonText("Purchase Credits");
      }, 5000);
    } else {
      // User confirmed, proceed with checkout
      setIsConfirmingMonthly(false);
      setStripeButtonText("Purchase Credits"); // Reset text before starting checkout
      clearTimeout(confirmTimeoutRefMonthly.current);
      startMonthlyCheckoutSession();
    }
  };

  // Function to handle Proceed Button Click
  const handleProceedClick = () => {
    if (paymentType === 'monthly') {
      handleMonthlyCheckoutClick();
    } else {
      startOneTimeCheckoutSession();
    }
  };

  const planClass = selectedPlan === 'Base' ? 'base-plan' : 'bump-plan';

  return (
    <div className="userplan-popup-overlay" onClick={onClose}>
      <div
        className={`userplan-popup-content ${planClass}`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close Button */}
        <button
          className="userplan-close-button"
          onClick={onClose}
          aria-label="Close Popup"
        >
          &times;
        </button>

        {/* Existing Content */}
        <div className="userplan-left-content">
          {/* Header with title and toggle buttons */}
          <div className="userplan-popup-header">
            <div className="userplan-title">
              <div>Upgrade for more features</div>
            </div>
            <div className="userplan-toggle-container">
              <button
                className={`userplan-payment-type-btn ${paymentType === 'monthly' ? 'active' : ''}`}
                onClick={() => handlePaymentTypeChange('monthly')}
              >
                Monthly Plan
                {/* Discount Badge - Always Visible */}
                <span className="userplan-payment-type-badge">SAVE up to 20% 🤠</span>
              </button>
              <button
                className={`userplan-payment-type-btn ${paymentType === 'one_time' ? 'active' : ''}`}
                onClick={() => handlePaymentTypeChange('one_time')}
              >
                One Time Purchase
              </button>
            </div>
          </div>


         {/* Credit Selection */}
         <div className="userplan-credit-selection">
            <span className="userplan-amount-label">Select your credit amount:</span>
            <div className="userplan-amount-buttons">
              {amountOptions.map((amount) => (
                <button
                  key={amount}
                  className={`userplan-amount-btn ${selectedAmount === amount ? 'selected' : ''}`}
                  onClick={() => handleAmountChange(amount)}
                  aria-pressed={selectedAmount === amount}
                >
                  {amount}
                </button>
              ))}
            </div>
          </div>

          {/* Proceed Button with Updated Logic */}
          <button
            className="userplan-proceed-button"
            onClick={handleProceedClick}
            disabled={
              (paymentType === 'monthly' && (isLoadingMonthly || isSuccessMonthly)) ||
              (paymentType === 'one_time' && (isLoadingOneTime || isSuccessOneTime))
            }
          >
            {paymentType === 'monthly'
              ? (isSuccessMonthly
                ? "Credits Added 🎉"
                : (isConfirmingMonthly
                  ? "Confirm?"
                  : stripeButtonText))
              : (isSuccessOneTime
                ? "Credits Added 🎉"
                : stripeButtonText)
            }
          </button>
          {/* Plans Container */}
          <div className="userplan-plans-container">
            {['Base', 'Bump'].map((plan) => {
              const isMonthly = paymentType === 'monthly';
              const currentPrice = calculatePlanPrice(plan, selectedAmount, paymentType);
              const originalPrice = pricingDetails[plan].originalPer100
                ? (selectedAmount / 100) * pricingDetails[plan].originalPer100
                : null;

              return (
                <div
                  key={plan}
                  className={`userplan-plan-card ${selectedPlan === plan ? 'selected' : 'unselected'}`}
                  onClick={() => handlePlanChange(plan)}
                >
                  <div className="userplan-plan-header">
                    <img src={pricingDetails[plan].logo} alt={`${plan} Plan Logo`} className="userplan-plan-logo" />
                    <h3>{plan} {isMonthly ? 'Plan' : 'Credits'}</h3>
                  </div>

                  {/* Discount Badge - Only on Selected Plan Card and Monthly Plan */}
                  {selectedPlan === plan && isMonthly && (
                    <div className="userplan-discount-badge">20% OFF</div>
                  )}

                  {/* Display Original Price with Strikethrough if Monthly */}
                  {isMonthly && (
                    <p className="userplan-original-price">
                      ${pricingDetails[plan].originalPer100 ? originalPrice.toFixed(2) : ''} USD
                    </p>
                  )}

                  {/* Display Current Price */}
                  <p className="userplan-price">
                    ${currentPrice} USD
                    <span>{isMonthly ? "/mo" : ""}</span>
                  </p>

                  <p
                    className="userplan-upload-minutes"
                    style={{ color: plan === 'Base' ? '#ffbf00' : '#4add80' }}
                  >
                    {selectedAmount} Upload Minutes
                  </p>
                  <p className="userplan-billing-info">
                    {pricingDetails[plan].billingInfoMonthly}
                  </p>
                  <ul className="userplan-features-list">
                    {pricingDetails[plan].features.map((feature, index) => (
                      <li key={index}>
                        <img src={checkmarkIcon} alt="Checkmark" className="feature-checkmark-icon" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
        <div className="userplan-image-wrapper">
          <img
            src={noAccessImageActive}
            alt="Background thumbnail"
            className="userplan-background-image"
          />
        </div>
      </div>
    </div>
  );
};

export default UserPaidPopup;
