// src/components/UploadWorkspace/UploadWorkspace.js

import React, { useState } from 'react';
import './uploadWorkspace.css'; // CSS specific to UploadWorkspace

// Import the newly created components
import LinkWorkspace from './YTworkspaceupload/linkWorkspace'; // Ensure the file is named LinkWorkspace.js
import LocalWorkspace from './Localworkspaceupload/localWorkspace'; // Ensure the file is named LocalWorkspace.js
import LocalVideoDisplay from './Localworkspaceupload/LocalVideoDisplay'; // Ensure the file is named LocalVideoDisplay.js

const UploadWorkspace = ({
  isTranscriptAvailable,
  isLocalTranscriptAvailable,
  content_id,
  isYTProcessing,
  isLOCALProcessing,
  isTranscriptEmpty,
}) => {
  // State to track if there's input in LinkWorkspace
  const [hasInput, setHasInput] = useState(false);

  // Callback to update the state based on input
  const handleInputChange = (inputPresent) => {
    setHasInput(inputPresent);
  };

  return (
    <div className="worklink-uploaddashboard">
      <div className="upload-workspace-container">
        {/* Render LinkWorkspace only if isLocalTranscriptAvailable and isLOCALProcessing are false */}
        {!isLocalTranscriptAvailable && !isLOCALProcessing && (
          <LinkWorkspace
            onInputChange={handleInputChange}
            content_id={content_id}
            isTranscriptAvailable={isTranscriptAvailable}
            isYTProcessing={isYTProcessing}
          />
        )}

        {/* Render LocalWorkspace only if hasInput, isLocalTranscriptAvailable, isYTProcessing, and isLOCALProcessing are false */}
        {!hasInput && !isLocalTranscriptAvailable && !isYTProcessing && !isLOCALProcessing && (
          <LocalWorkspace
            content_id={content_id}
            isLOCALProcessing={isLOCALProcessing}
          />
        )}

        {/* Conditionally render LocalVideoDisplay if isLocalTranscriptAvailable or isLOCALProcessing is true */}
        {(isLocalTranscriptAvailable || isLOCALProcessing) && (
          <LocalVideoDisplay
            content_id={content_id}
            isLocalTranscriptAvailable={isLocalTranscriptAvailable}
            isLOCALProcessing={isLOCALProcessing}
            isTranscriptEmpty={isTranscriptEmpty}
          />
        )}
      </div>
    </div>
  );
};

export default UploadWorkspace;
