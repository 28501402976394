import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import '../../myVideoPage/VideoValuePage.css';

//FIREBASE
import { doc, onSnapshot, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

//FUNCTIONS
import { reprocessBump } from '../reprocessBump';

//IMAGES
import copyIcon from '../../assets/images/copyicon-v1.png';
import checkmarkIcon from '../../assets/images/checkmark-v1.png';
import starIcon from '../../assets/images/star-v1.png';
import timeLogo from '../../assets/images/clock-v2.png';
import timestampLogo from '../../assets/images/timestamp-v2.png';
import brandLogo from '../../assets/images/process-v1.png';
import helpIcon from '../../assets/images/v2-helpicon.png';
import brandingLogo from '../../assets/images/v2-settings.png';
import reloadLogo from '../../assets/images/realod-v2.png';
import bumplogo from '../../assets/images/bumparrow.png';
import reloadDarkLogo from '../../assets/images/realod-dark-v2.png';

import processlogo from '../../assets/gifmedia/bumpups-2.gif';

const ValueAddMyBump = ({ videoId, isProcessedbyAI, user, isTranscriptEmpty }) => {
  return (
      <div className="addvalueBump-dashboard-main-videoid">
          {!isProcessedbyAI && <ProcessingOverlay isTranscriptEmpty={isTranscriptEmpty} />}
          <div className="addvalueBump-videoid"> 
              <div className="dashboard-content-wrapper-videoid">
                  <div className="addvalueBump-left-videoid">
                      <VideoSummary videoId={videoId} user={user} />
                      <VideoTimestamps videoId={videoId} user={user} />
                  </div>
                  <div className="addvalueBump-center-videoid">
                      <VideoTitles videoId={videoId} user={user} />
                      <KeyTakeaways videoId={videoId} user={user} />
                  </div>
                  <div className="addvalueBump-right-videoid">
                      {isProcessedbyAI && <ReloadData videoId={videoId} user={user} />}
                      <VideoTags videoId={videoId} user={user} />
                      <VideoSuggestions videoId={videoId} />
                  </div>
              </div>
          </div>
      </div>
  );
};





const ReloadData = ({ videoId, user }) => {
    const [progress, setProgress] = useState(0);
    const [showReload, setShowReload] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [isDebounced, setIsDebounced] = useState(false);
  
    useEffect(() => {
      if (user && user.uid) {
        const debounceKey = `${user.uid}-${videoId}-debounce`;
        const debounceTime = sessionStorage.getItem(debounceKey);
  
        if (debounceTime && new Date().getTime() < parseInt(debounceTime, 10)) {
          setIsDebounced(true);
        }
  
        const userDocRef = doc(db, 'users', user.uid);
        const videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);
  
        const unsubscribe = onSnapshot(videoDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const videoData = docSnapshot.data();
            const { summary, takeaways, hashtags, timestamps, titles } = videoData;
  
            if (summary && takeaways && hashtags && timestamps && titles) {
              setShowReload(false);
            } else {
              setShowReload(true);
            }
          } else {
            setShowReload(true);
          }
        });
  
        return () => unsubscribe();
      }
    }, [videoId, user]);
  
    const incrementProgress = (currentProgress, targetProgress) => {
      if (currentProgress < targetProgress) {
        const increment = Math.min(5, targetProgress - currentProgress);
        setProgress((prevProgress) => prevProgress + increment);
  
        const nextIncrement = Math.max(1000, (60 * 1000) / ((100 - currentProgress) / 5));
        setTimeout(() => {
          incrementProgress(currentProgress + increment, targetProgress);
        }, nextIncrement);
      }
    };
  
    const handleReload = async () => {
      const debounceKey = `${user.uid}-${videoId}-debounce`;
      setIsDebounced(true);
      sessionStorage.setItem(debounceKey, new Date().getTime() + 60000);
  
      setProgress(10);
      setLoadingComplete(false);
  
      setProgress(20);
  
      try {
        const backendResponse = await reprocessBump(videoId);
  
        if (backendResponse.is503Error || backendResponse.isinternalError) {
          setProgress(0);
          setLoadingComplete(false);
          setIsDebounced(false);
          sessionStorage.removeItem(debounceKey);
        } else {
          setProgress(50);
  
          incrementProgress(50, 100);
  
          const checkDataPointsFilled = async () => {
            const userDocRef = doc(db, 'users', user.uid);
            const videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);
  
            const unsubscribe = onSnapshot(videoDocRef, (docSnapshot) => {
              if (docSnapshot.exists()) {
                const videoData = docSnapshot.data();
                const { summary, takeaways, hashtags, timestamps, titles } = videoData;
  
                if (summary && takeaways && hashtags && timestamps && titles) {
                  setProgress(100);
                  setLoadingComplete(true);
                  unsubscribe();
                }
              }
            });
          };
  
          checkDataPointsFilled();
        }
      } catch (error) {
        // console.error('Error calling backend function:', error);
        setProgress(0);
        setLoadingComplete(false);
        setIsDebounced(false);
        sessionStorage.removeItem(debounceKey);
      }
    };
  
    return (
        <div className={`reload-data ${showReload ? '' : 'hidden'}`}>
          <LoadingBar color="#4add80" progress={progress} />
          <div className="reload-data-container">
            <button
              className="reload-data-button"
              onClick={handleReload}
              disabled={isDebounced}
              style={{ 
                opacity: isDebounced ? 0.8 : 1,
                cursor: isDebounced ? 'not-allowed' : 'pointer'
              }}
            >
              <img src={loadingComplete ? processlogo : reloadDarkLogo} alt="Reload Logo" className="reload-logo" />
              {loadingComplete ? 'Processing...' : 'Reprocess'}
            </button>
            <div className="help-icon-container-reload-videoid">
              <img src={helpIcon} alt="Help Icon" className="help-icon-rload-videoid" />
              <div className="help-tooltip-reprocess-videoid">
                Use the reprocess button to reprocess your video for <b>FREE</b> in case of failed outputs or if you're viewing a legacy processed video.
                <a href="https://intercom.help/bumpups/en/articles/9416266-does-reprocessing-my-video-deduct-minutes" className="learn-more-videoid" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
              </div>
            </div>
          </div>
        </div>
      );
  };
  



const VideoTitles = ({ videoId, user }) => {
  const [titles, setTitles] = useState([]);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchTitles = async () => {
      try {
        if (!user) {
          return;
        }

        const storageTitleKey = `${user.uid} bump-${videoId}-titles`;
        const storedTitles = localStorage.getItem(storageTitleKey);

        let videoDocRef;
        if (storedTitles) {
          setTitles(JSON.parse(storedTitles));
        } else {
          const userDocRef = doc(db, 'users', user.uid);
          videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);
          const videoDoc = await getDoc(videoDocRef);

          if (videoDoc.exists()) {
            const videoData = videoDoc.data();
            const titlesArray = videoData.titles || [];
            setTitles(titlesArray);
            localStorage.setItem(storageTitleKey, JSON.stringify(titlesArray));
          }
        }

        // Add snapshot listener
        if (!videoDocRef) {
          const userDocRef = doc(db, 'users', user.uid);
          videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);
        }
        const unsubscribe = onSnapshot(videoDocRef, (doc) => {
          if (doc.exists()) {
            const videoData = doc.data();
            const newTitles = videoData.titles || [];
            setTitles(newTitles);
            localStorage.setItem(storageTitleKey, JSON.stringify(newTitles));
          }
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        // console.error('Error fetching titles:', error);
      }
    };

    fetchTitles();
  }, [user,videoId]);

  const copyTitlesToClipboard = () => {
    const titlesString = titles.join('\n');
    navigator.clipboard.writeText(titlesString);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="key-takeaways-box-videoid">
      <div className="key-takeaways-header-videoid">
        <div className="video-summary-title-container-videoid">
          <h3 className="key-takeaways-title-videoid">Video Titles</h3>
          <div className="help-icon-container-videoid">
            <img src={helpIcon} alt="Help Icon" className="help-icon-videoid" />
            <div className="help-tooltip-videoid">
              These video titles are optimized to enhance your video's visibility and engagement.
              <a href="https://intercom.help/bumpups/en/articles/9542805-how-do-optimized-video-titles-help-you" className="learn-more-videoid" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
            </div>
          </div>
        </div>
        <button className="copy-summary-btn-videoid" onClick={copyTitlesToClipboard}>
          <div className="tooltip-videoid">
            <img src={copied ? checkmarkIcon : copyIcon} alt={copied ? "Copied" : "Copy"} className="copy-icon-videoid" />
            <span className="tooltiptext-videoid">{copied ? "Copied" : "Copy"}</span>
          </div>
        </button>
      </div>
      <div className="key-takeaways-content-videoid">
        {titles.length > 0 ? (
          titles.map((title, index) => (
            <div key={index} className="key-takeaway-videoid">
              <span className="key-takeaway-text-videoid">{title}</span>
            </div>
          ))
        ) : (
          <div className="video-summary-content-videoid">
            <img src={reloadLogo} alt="Reload Logo" className="reload-logo-videoid" />
          </div>
        )}
      </div>
    </div>
  );
};




const VideoSummary = ({ videoId, user }) => {
  const [summary, setSummary] = useState("");
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        if (!user) {
          return;
        }

        const storageSummaryKey = `${user.uid} bump-${videoId}-summary`;
        const storedSummary = localStorage.getItem(storageSummaryKey);

        if (storedSummary) {
          setSummary(storedSummary);
        } else {
          const userDocRef = doc(db, 'users', user.uid);
          const videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);
          const videoDoc = await getDoc(videoDocRef);

          if (videoDoc.exists()) {
            const videoData = videoDoc.data();
            const fetchedSummary = videoData.summary || "";
            setSummary(fetchedSummary);
            localStorage.setItem(storageSummaryKey, fetchedSummary);
          }
        }

        // Add snapshot listener
        const userDocRef = doc(db, 'users', user.uid);
        const videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);
        const unsubscribe = onSnapshot(videoDocRef, (doc) => {
          if (doc.exists()) {
            const videoData = doc.data();
            const newSummary = videoData.summary || "";
            setSummary(newSummary);
            localStorage.setItem(storageSummaryKey, newSummary);
          }
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        // console.error('Error fetching summary:', error);
      }
    };

    fetchSummary();
  }, [user,videoId]);

  const copySummaryToClipboard = () => {
    navigator.clipboard.writeText(summary);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="video-summary-box-videoid">
      <div className="video-summary-header-videoid">
        <div className="video-summary-title-container-videoid">
          <h3 className="video-summary-title-videoid">Video Description</h3>
          <div className="help-icon-container-videoid">
            <img src={helpIcon} alt="Help Icon" className="help-icon-videoid" />
            <div className="help-tooltip-videoid">
              We have written an optimized description to help viewers find your videos more easily through search, by including keywords at the beginning of the overview.
              <a href="https://intercom.help/bumpups/en/articles/9381182-how-does-a-video-description-help-you" className="learn-more-videoid" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
            </div>
          </div>
        </div>
        <div className="action-buttons-videoid">
          <button className="copy-summary-btn-videoid" onClick={copySummaryToClipboard}>
            <div className="tooltip-videoid">
              <img src={copied ? checkmarkIcon : copyIcon} alt={copied ? "Copied" : "Copy"} className="copy-icon-videoid" />
              <span className="tooltiptext-videoid">{copied ? "Copied" : "Copy"}</span>
            </div>
          </button>
        </div>
      </div>
      <div className="video-summary-content-videoid">
        {summary ? (
          <p className="video-summary-videoid">{summary}</p>
        ) : (
          <img src={reloadLogo} alt="Reload Logo" className="reload-logo-videoid" />
        )}
      </div>
    </div>
  );
};





const KeyTakeaways = ({ videoId, user }) => {
  const [takeaways, setTakeaways] = useState([]);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchTakeaways = async () => {
      try {

        if (!user) {
          return;
        }

        const storageTakeawayKey = `${user.uid} bump-${videoId}-takeaways`;
        const storedTakeaways = localStorage.getItem(storageTakeawayKey);

        let videoDocRef;
        if (storedTakeaways) {
          setTakeaways(JSON.parse(storedTakeaways));
        } else {
          const userDocRef = doc(db, 'users', user.uid);
          videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);
          const videoDoc = await getDoc(videoDocRef);

          if (videoDoc.exists()) {
            const videoData = videoDoc.data();
            const takeawaysArray = videoData.takeaways || [];
            setTakeaways(takeawaysArray);
            localStorage.setItem(storageTakeawayKey, JSON.stringify(takeawaysArray));
          }
        }

        // Add snapshot listener
        if (!videoDocRef) {
          const userDocRef = doc(db, 'users', user.uid);
          videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);
        }
        const unsubscribe = onSnapshot(videoDocRef, (doc) => {
          if (doc.exists()) {
            const videoData = doc.data();
            const newTakeaways = videoData.takeaways || [];
            setTakeaways(newTakeaways);
            localStorage.setItem(storageTakeawayKey, JSON.stringify(newTakeaways));
          }
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        // console.error('Error fetching takeaways:', error);
      }
    };

    fetchTakeaways();
  }, [user,videoId]);

  const copyTakeawaysToClipboard = () => {
    const takeawaysString = takeaways.join('\n');
    navigator.clipboard.writeText(takeawaysString);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const parseTakeaway = (takeaway) => {
    const emojiMatch = takeaway.match(/(\p{Emoji_Presentation}|\p{Extended_Pictographic}|\p{Emoji_Modifier_Base}|\p{Emoji_Component})+/gu);
    if (emojiMatch) {
      const emoji = emojiMatch[0];
      const text = takeaway.replace(emoji, '').trim();
      return { emoji, text };
    }
    return { emoji: '', text: takeaway };
  };

  return (
    <div className="key-takeaways-box-videoid">
      <div className="key-takeaways-header-videoid">
        <div className="video-summary-title-container-videoid">
          <h3 className="key-takeaways-title-videoid">Key Takeaways</h3>
          <div className="help-icon-container-videoid">
            <img src={helpIcon} alt="Help Icon" className="help-icon-videoid" />
            <div className="help-tooltip-videoid">
              These key takeaways enhance your video description with relevant keywords, increasing visibility in Google, Bing, and other search engines.
              <a href="https://intercom.help/bumpups/en/articles/9381198-how-do-key-takeaways-help-you" className="learn-more-videoid" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
            </div>
          </div>
        </div>
        <button className="copy-summary-btn-videoid" onClick={copyTakeawaysToClipboard}>
          <div className="tooltip-videoid">
            <img src={copied ? checkmarkIcon : copyIcon} alt={copied ? "Copied" : "Copy"} className="copy-icon-videoid" />
            <span className="tooltiptext-videoid">{copied ? "Copied" : "Copy"}</span>
          </div>
        </button>
      </div>
      <div className="key-takeaways-content-videoid">
        {takeaways.length > 0 ? (
          takeaways.map((takeaway, index) => {
            const { emoji, text } = parseTakeaway(takeaway);
            return (
              <div key={index} className="key-takeaway-videoid">
                <span className="key-takeaway-emoji-videoid">{emoji}</span>
                <span className="key-takeaway-text-videoid">{text}</span>
              </div>
            );
          })
        ) : (
          <div className="video-summary-content-videoid">
            <img src={reloadLogo} alt="Reload Logo" className="reload-logo-videoid" />
          </div>
        )}
      </div>
    </div>
  );
};







const VideoTags = ({ videoId, user }) => {
  const [tags, setTags] = useState([]);
  const [copied, setCopied] = useState(false);
  const [highlightedTags, setHighlightedTags] = useState([]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        if (!user) {
          return;
        }

        const storageTagKey = `${user.uid} bump-${videoId}-tags`;
        const storedTags = localStorage.getItem(storageTagKey);

        const userDocRef = doc(db, 'users', user.uid);
        const videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);

        if (storedTags) {
          setTags(storedTags.split(', '));
        } else {
          const videoDoc = await getDoc(videoDocRef);

          if (videoDoc.exists()) {
            const videoData = videoDoc.data();
            const tagsArray = videoData.hashtags || [];
            setTags(tagsArray);
            localStorage.setItem(storageTagKey, tagsArray.join(', '));
          }
        }

        // Add snapshot listener
        const unsubscribe = onSnapshot(videoDocRef, (doc) => {
          if (doc.exists()) {
            const videoData = doc.data();
            const newTags = videoData.hashtags || [];
            setTags(newTags);
            localStorage.setItem(storageTagKey, newTags.join(', '));
          }
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        // console.error('Error fetching tags:', error);
      }
    };

    fetchTags();
  }, [user, videoId]);

  useEffect(() => {
    // Highlight the first 5 unique tags
    const highlightIndices = Array.from({ length: Math.min(5, tags.length) }, (_, i) => i);
    setHighlightedTags(highlightIndices);
  }, [tags]);

  const copyTagsToClipboard = () => {
    const tagsString = tags.join(', ');
    navigator.clipboard.writeText(tagsString);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="video-tags-box">
      <div className="video-tags-header">
        <div className="video-summary-title-container-videoid">
          <h3 className="video-tags-title">Video Tags</h3>
          <div className="help-icon-container-videoid">
            <img src={helpIcon} alt="Help Icon" className="help-icon-videoid" />
            <div className="help-tooltip-videoid">
              We created hashtags to make your videos more discoverable by associating them with specific topics, trends, or communities, helping attract viewers in your brand's target audience.
              <a href="https://intercom.help/bumpups/en/articles/9381190-how-do-video-tags-help-you" className="learn-more-videoid" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
            </div>
          </div>
        </div>
        <div className="copy-section">
          <div className="tooltip-videoid">
            <img src={starIcon} alt="Best Tags" className="star-icon-left" />
            <span className="tooltiptext-left">Best Tags</span>
          </div>
          <button className="copy-summary-btn-videoid" onClick={copyTagsToClipboard}>
            <div className="tooltip-videoid">
              <img src={copied ? checkmarkIcon : copyIcon} alt={copied ? "Copied" : "Copy"} className="copy-icon-videoid" />
              <span className="tooltiptext-videoid">{copied ? "Copied" : "Copy"}</span>
            </div>
          </button>
        </div>
      </div>
      <div className="video-tags-content">
        {tags.length > 0 ? (
          tags.map((tag, index) => (
            <div key={index} className={`video-tag ${highlightedTags.includes(index) ? 'highlighted' : ''}`}>
              {highlightedTags.includes(index) && <img src={starIcon} alt="Star" className="star-icon" />}
              {tag}
            </div>
          ))
        ) : (
          <div className="video-tassg-content-videoid">
          <img src={reloadLogo} alt="Reload Logo" className="reload-logo-videoid" />
          </div>
        )}
      </div>
    </div>
  );
};






const VideoTimestamps = ({ videoId, user }) => {
    const [timestamps, setTimestamps] = useState([]);
    const [copied, setCopied] = useState(false);
  
    useEffect(() => {
      const fetchTimestamps = async () => {
        try {
          if (!user) {
            return;
          }
  
          const storageTimestampKey = `${user.uid} bump-${videoId}-timestamps`;
          const storedTimestamps = localStorage.getItem(storageTimestampKey);
  
          const userDocRef = doc(db, 'users', user.uid);
          const videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);
  
          if (storedTimestamps) {
            setTimestamps(storedTimestamps.split('\n'));
          } else {
            const videoDoc = await getDoc(videoDocRef);
  
            if (videoDoc.exists()) {
              const videoData = videoDoc.data();
              const timestampsString = videoData.timestamps || '';
              const timestampsArray = timestampsString ? timestampsString.split('\n') : [];
              setTimestamps(timestampsArray);
              localStorage.setItem(storageTimestampKey, timestampsString);
            }
          }
  
          // Add snapshot listener
          const unsubscribe = onSnapshot(videoDocRef, (doc) => {
            if (doc.exists()) {
              const videoData = doc.data();
              const newTimestampsString = videoData.timestamps || '';
              const newTimestamps = newTimestampsString ? newTimestampsString.split('\n') : [];
              setTimestamps(newTimestamps);
              localStorage.setItem(storageTimestampKey, newTimestampsString);
            }
          });
  
          return () => {
            unsubscribe();
          };
        } catch (error) {
          // console.error('Error fetching timestamps:', error);
        }
      };
  
      fetchTimestamps();
    }, [user, videoId]);
  
    const copyTimestampsToClipboard = () => {
      const timestampsString = timestamps.join('\n');
      navigator.clipboard.writeText(timestampsString);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    };
  
    return (
      <div className="video-timestamps-box-videoid">
        <div className="video-timestamps-header-videoid">
          <div className="video-summary-title-container-videoid">
            <h3 className="video-timestamps-title-videoid">Video Timestamps</h3>
            <div className="help-icon-container-videoid">
              <img src={helpIcon} alt="Help Icon" className="help-icon-videoid" />
              <div className="help-tooltip-videoid">
                Break your video into sections with individual previews, adding context to each part and letting you rewatch easily. Optimizing timestamps can enhance SEO by making your content more discoverable.
                <a href="https://intercom.help/bumpups/en/articles/9377422-how-do-video-timestamps-help-you" className="learn-more-videoid" target="_blank" rel="noopener noreferrer">LEARN MORE</a>
              </div>
            </div>
          </div>
          <div className="icon-buttons-videoid">
            <Link to="/settings/creator" className="branding-btn-videoid">
              <div className="tooltip-videoid">
                <img src={brandingLogo} alt="Branding" className="branding-icon-videoid" />
                <span className="tooltiptext-videoid">Branding</span>
              </div>
            </Link>
            <button className="copy-summary-btn-videoid" onClick={copyTimestampsToClipboard}>
              <div className="tooltip-videoid">
                <img src={copied ? checkmarkIcon : copyIcon} alt={copied ? "Copied" : "Copy"} className="copy-icon-videoid" />
                <span className="tooltiptext-videoid">{copied ? "Copied" : "Copy"}</span>
              </div>
            </button>
          </div>
        </div>
        <div className="video-timestamps-content-videoid">
          {timestamps.length > 0 ? (
            timestamps.map((timestamp, index) => {
              const [time, description] = timestamp.split(' - ');
              return (
                <div key={index} className="video-timestamp-videoid">
                  <span className="video-timestamp-number-videoid">{time} - </span>
                  <span className="video-timestamp-description-videoid">{description}</span>
                </div>
              );
            })
          ) : (
            <div className="video-summary-content-videoid">
              <img src={reloadLogo} alt="Reload Logo" className="reload-logo-videoid" />
            </div>
          )}
        </div>
      </div>
    );
  };




  const VideoSuggestions = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = 3; // Assuming there are 3 pages

    // Updated content for different pages with associated links
    const pageContents = [
      {
        title: "Bump AI variables",
        description: "Use variables like {{VIDEO_DESCRIPTION}} for Bump AI content.",
        link: "https://intercom.help/bumpups/en/articles/9742235-what-are-the-bump-ai-variables",
        image: brandLogo
      },
      {
        title: "What timestamps do",
        description: "Break your videos into sections with timestamps, improving viewer navigation and boosting SEO.",
        link: "https://intercom.help/bumpups/en/articles/9377422-how-do-video-timestamps-help-you",
        image: timestampLogo
      },
      {
        title: "Your processing time",
        description: "Processing time on Bumpups is deducted per video, with beneficial rounding up.",
        link: "https://intercom.help/bumpups/en/articles/8858577-how-are-credits-deducted", // Replace with actual URL
        image: timeLogo
      },
    ];

    const nextPage = () => {
      setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : prevPage));
    };

    const prevPage = () => {
      setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };

    const openLink = () => {
      const { link } = pageContents[currentPage - 1];
      window.open(link, '_blank');
    };

    // Get the content for the current page
    const { title, description, image } = pageContents[currentPage - 1];

    return (
      <div className="video-suggestions-container">
      <div className="video-suggestions-card">
        <div className="video-suggestions-content">
          <h3>Suggestions for you</h3>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className="video-suggestions-icon">
          <img src={image} alt={title} /> {/* Use dynamic image and alt text */}
        </div>
      </div>
        <div className="video-suggestions-actions">
          <button className="video-suggestions-start-btn" onClick={openLink}>READ NOW</button>
          <div className="video-suggestions-pagination">
            <span className="pagination-arrowbox" onClick={prevPage}>&lt;</span>
            {`${currentPage}/${totalPages}`}
            <span className="pagination-arrowbox" onClick={nextPage}>&gt;</span>
          </div>
        </div>
      </div>
    );
  };



  const ProcessingOverlay = ({ isTranscriptEmpty }) => {
    let title, description, link;
    if (isTranscriptEmpty) {
      title = "What happened?";
      description = "Videos with limited or no spoken words, like music videos, may not meet our requirements.";
      link = "https://intercom.help/bumpups/en/articles/8835886-what-are-the-video-requirements";
    } else {
      title = "Bump AI Viewing";
      description = "Our AI is watching your video to generate optimal descriptions, titles, key takeaways, timestamps, and tags.";
      link = "https://intercom.help/bumpups/en/articles/9742280-how-does-bump-ai-process-my-video";
    }
  
    const openLink = () => {
      window.open(link, "_blank");
    };
  
    return (
      <div>
        <div className="valueBump-blur-background" />
        <div className="valueBump-processing-container">
          <div className="valueBump-processing-card">
            <div className="valueBump-processing-content">
              <h3>{isTranscriptEmpty ? "Video failed." : "What is happening?"}</h3>
              <h2>{title}</h2>
              <p>{description}</p>
            </div>
            <div className="valueBump-processing-icon">
              <img src={isTranscriptEmpty ? helpIcon : bumplogo} alt={title} />
            </div>
          </div>
          <div className="valueBump-processing-actions">
            <button className="valueBump-processing-start-btn" onClick={openLink}>
              READ NOW
            </button>
          </div>
        </div>
      </div>
    );
  };


export default ValueAddMyBump;
