import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import AppTransition from '../../components/AppTransition';
import Sidebar from '../../myDashboard/Sidebar';
import CloseSidebar from '../../myDashboard/CloseSidebar';
import EditMyBump from './editMyBump';
import ValueAddMyBump from './valueaddMyBump';
import './viewDoneBump.css';

function ViewDoneBump({ isSidebarOpen, toggleSidebar }) {
  const user = auth.currentUser;
  const { videoId } = useParams();

  const [isProcessedbyAI, setIsProcessedbyAI] = useState(false);
  const [isTranscriptReady, setIsTranscriptReady] = useState(false);
  const [isTranscriptEmpty, setIsTranscriptEmpty] = useState(false);
  const [isYouTubePosted, setYouTubePosted] = useState(false);

  const [videoTitle, setVideoTitle] = useState('');
  const [videoQuality, setVideoQuality] = useState('--');
  const [summary, setSummary] = useState('');
  const [takeaways, setTakeaways] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [timestamps, setTimestamps] = useState([]);

  const [videoDuration, setVideoDuration] = useState(0);
  const [videoUploadTime, setVideoUploadTime] = useState(null);
  const [videoSize, setVideoSize] = useState(0);

  useEffect(() => {
    if (user && user.uid) {
      const userDocRef = doc(db, 'users', user.uid);
      const videoDocRef = doc(userDocRef, 'bumpContent', `bump-${videoId}`);

      const fetchVideoMetaData = async () => {
        try {
          const videoDoc = await getDoc(videoDocRef);
          if (videoDoc.exists()) {
            const videoData = videoDoc.data();
            setVideoTitle(videoData.video_file_name || '');
            setVideoQuality(videoData.video_quality || '');
            setSummary(videoData.summary || '');
            setTakeaways(videoData.takeaways || []);
            setHashtags(videoData.hashtags || []);
            setTimestamps(videoData.timestamps || []);
            setVideoDuration(videoData.video_duration_in_seconds || 0);
            setVideoUploadTime(videoData.video_upload_time?.toDate() || null);
            setVideoSize(videoData.video_blob_size_mb || 0);
          }
        } catch (error) {
          // Handle error if needed
        }

        const unsubscribeMeta = onSnapshot(videoDocRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const videoData = docSnapshot.data();
            setVideoTitle(videoData.video_file_name || '');
            setVideoQuality(videoData.video_quality || '');
            setSummary(videoData.summary || '');
            setTakeaways(videoData.takeaways || []);
            setHashtags(videoData.hashtags || []);
            setTimestamps(videoData.timestamps || []);
            setVideoDuration(videoData.video_duration_in_seconds || 0);
            setVideoUploadTime(videoData.video_upload_time?.toDate() || null);
          }
        }, (error) => {
          // Handle error if needed
        });

        return unsubscribeMeta;
      };

      const unsubscribe = onSnapshot(videoDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const videoData = docSnapshot.data();
          const {
            summary,
            takeaways,
            hashtags,
            timestamps,
            titles,
            is_transcript_ready,
            is_youtube_posted,
            is_transcript_empty,
          } = videoData;

          setIsProcessedbyAI(!!summary || !!takeaways || !!hashtags || !!timestamps || !!titles);
          setIsTranscriptReady(!!is_transcript_ready);
          setIsTranscriptEmpty(!!is_transcript_empty);
          setYouTubePosted(!!is_youtube_posted);
        } else {
          setIsProcessedbyAI(false);
          setIsTranscriptReady(false);
          setIsTranscriptEmpty(false);
          setYouTubePosted(false);
        }
      });

      fetchVideoMetaData();

      return () => {
        unsubscribe();
      };
    }
  }, [videoId, user]);

  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const fetchAccessToken = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const userId = user.uid;
      const accessDocRef = doc(db, 'users', userId, 'contentAccess', 'youtubeAccess');

      try {
        const accessSnapshot = await getDoc(accessDocRef);

        if (accessSnapshot.exists()) {
          const fetchedAccessToken = accessSnapshot.data().accessToken || null;
          setAccessToken(fetchedAccessToken);
        }

        onSnapshot(accessDocRef, (doc) => {
          if (doc.exists()) {
            const fetchedAccessToken = doc.data().accessToken || null;
            setAccessToken(fetchedAccessToken);
          }
        });
      } catch (error) {
        // Handle error if needed
      }
    };

    fetchAccessToken();
  }, []);

  return (
    <div className="viewdonebump-uploaddashboard">
      {isSidebarOpen && <Sidebar />}
      {!isSidebarOpen && <CloseSidebar />}
      <AppTransition>
        <div className="viewdonebump-dashboard-main">
          <div className="viewdonebump-dashboard-content-wrapper">
            <div className="viewdonebump-dashboard-contentgeneral">
              <EditMyBump 
                videoId={videoId} 
                isTranscriptReady={isTranscriptReady} 
                isTranscriptEmpty={isTranscriptEmpty}
                isProcessedbyAI={isProcessedbyAI} 
                isYouTubePosted={isYouTubePosted} 
                user={user} 
                accessToken={accessToken} 
                videoTitle={videoTitle} 
                videoQuality={videoQuality}
                summary={summary}
                takeaways={takeaways}
                hashtags={hashtags}
                timestamps={timestamps} 
                videoDuration={videoDuration}            
                videoUploadTime={videoUploadTime}
                videoSize={videoSize}
              />
              <ValueAddMyBump videoId={videoId} isProcessedbyAI={isProcessedbyAI} user={user} isTranscriptEmpty={isTranscriptEmpty} />
            </div>
          </div>
        </div>
      </AppTransition>
    </div>
  );
}

export default ViewDoneBump;
