import React, { } from 'react';
import './editMyBump.css';
import ProgressBarMyBump from './ProgressBarMyBump';
import UserEditBumpSection from './userEditBumpSection';
import UserBumpDetailsSection from './UserBumpDetailsSection';






const EditMyBump = ({ videoId, user, isTranscriptReady, isProcessedbyAI, accessToken, videoTitle, videoQuality, isYouTubePosted, summary, takeaways, hashtags, timestamps, videoDuration, videoUploadTime, videoSize, isTranscriptEmpty }) => {



    return (
        <div className="editBump-section-container">
            <ProgressBarMyBump videoId={videoId} 
            user={user} 
            isTranscriptReady={isTranscriptReady} 
            isProcessedbyAI={isProcessedbyAI} 
            isYouTubePosted={isYouTubePosted} 
            videoTitle={videoTitle} 
            videoQuality={videoQuality} 
            videoDuration={videoDuration} 
            isTranscriptEmpty={isTranscriptEmpty} 

            />


            <div className="editBump-sections-wrapper">
                <UserEditBumpSection 
                    videoId={videoId} 
                    user={user} 
                    videoTitle={videoTitle}
                    summary={summary}
                    takeaways={takeaways}
                    hashtags={hashtags}
                    timestamps={timestamps} 
                    isProcessedbyAI={isProcessedbyAI}
                    isYouTubePosted={isYouTubePosted}
                    isTranscriptEmpty={isTranscriptEmpty} 
                />
                <UserBumpDetailsSection 
                    videoId={videoId} 
                    user={user} 
                    accessToken={accessToken} 
                    videoTitle={videoTitle} 
                    videoQuality={videoQuality} 
                    videoDuration={videoDuration} 
                    videoUploadTime={videoUploadTime} 
                    videoSize={videoSize} 
                    isTranscriptReady={isTranscriptReady}
                    isProcessedbyAI={isProcessedbyAI}
                    isTranscriptEmpty={isTranscriptEmpty} 

                />
            </div>
        </div>
    );



};



export default EditMyBump;