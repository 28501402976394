// src/components/TemplateWorkspace/updateWorkspace.js
import { getFunctions, httpsCallable } from 'firebase/functions';

// Initialize Firebase Functions
const functions = getFunctions();
const updateWorkspaceCallable = httpsCallable(functions, 'modifyWorkspace');

/**
 * Function to update an existing workspace via Firebase Cloud Functions
 * @param {Object} params - Parameters for workspace update
 * @returns {Promise<void>} - No return value is expected, just resolves on success
 */
const updateWorkspace = async ({
  user,
  content_id,
  workspaceTitle,
  isSCTimestampsEnabled,
  isSCDescriptionEnabled,
  isSCTakeawaysEnabled,
  isSCTitlesEnabled,
  isSCTagsEnabled,
  prompt1Input,
  prompt2Input,
  prompt3Input
}) => {

    // console.log('Updating workspace with the following details:');
    // console.log('User ID:', user.uid);
    // console.log('Content ID:', content_id);
    // console.log('Workspace Title:', workspaceTitle);
    // console.log('SCTimestamps Enabled:', isSCTimestampsEnabled);
    // console.log('SCDescription Enabled:', isSCDescriptionEnabled);
    // console.log('SCTakeaways Enabled:', isSCTakeawaysEnabled);
    // console.log('SCTitles Enabled:', isSCTitlesEnabled);
    // console.log('SCTags Enabled:', isSCTagsEnabled);
    // console.log('Prompt 1 Input:', prompt1Input);
    // console.log('Prompt 2 Input:', prompt2Input);
    // console.log('Prompt 3 Input:', prompt3Input);

    const workspaceData = {
        contentId: `workspace-${content_id}`,
        createdBy: user.uid,
        workspaceTitle: workspaceTitle,
        isSCTimestampsEnabled: isSCTimestampsEnabled,
        isSCDescriptionEnabled: isSCDescriptionEnabled,
        isSCTakeawaysEnabled: isSCTakeawaysEnabled,
        isSCTitlesEnabled: isSCTitlesEnabled,
        isSCTagsEnabled: isSCTagsEnabled,
        prompt1Input: prompt1Input,
        prompt2Input: prompt2Input,
        prompt3Input: prompt3Input,
        isTranscriptAvailable: false, // Assuming this is part of the update
    };

    try {
         await updateWorkspaceCallable({ workspaceData });

               // Clear local storage cache for the user's workspace videos
               const localStorageKey = `videoCache_${user.uid}_WorkspaceVideos`;
               localStorage.removeItem(localStorageKey);
            //    console.log(`Cleared localStorage cache for key: ${localStorageKey}`);

               
        // console.log('Workspace updated successfully.', response.data);
    } catch (error) {
        // console.error('Error updating workspace:', error);
        throw error;
    }
};

export { updateWorkspace };
