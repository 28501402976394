import { getFunctions, httpsCallable } from 'firebase/functions';


// FOR /CREATOR PAGE -- AUTHED YOUTUBERS PROCESSING
export const getYoutubeCaptionsPython = (videoId, durationInSeconds, thumbnailUrl, title, contentId, isReprocess) => {
  const functions = getFunctions();
  const getCaptionsPythonCallable = httpsCallable(functions, 'getCaptionsPython');
  const videoData = {
    'videoId': videoId,
    'durationInSeconds': durationInSeconds,
    'videoThumbnail': thumbnailUrl,
    'videoTitle': title,
    'contentId': contentId
  };
  let is503Error = false;
  let isunAuthError = false;

  const requestData = {
    'videoData': videoData,
    'isReprocess': isReprocess
  };

  return new Promise((resolve, reject) => {
    getCaptionsPythonCallable(requestData)
      .then((result) => {
        resolve({ is503Error, isunAuthError });
      })
      .catch((error) => {
        const code = error.code;

        if (code === 'functions/permission-denied') {
          isunAuthError = true;
        }
        if (code === 'functions/unavailable') {
          is503Error = true;
        }

        resolve({ is503Error, isunAuthError });
      });
  });
};





// FOR /LINKS PAGE -- FOR UNAUTH VIDEOS PROCESSED
export const getYoutubeURLUnAuthCaptions = ({
  videoId,
  durationInSeconds,
  thumbnailUrl,
  title,
  isReprocess,
  fullUrl,
  isPublic,
  contentId, // New parameter
}) => {
  const functions = getFunctions();
  const getCaptionsPythonCallable = httpsCallable(functions, 'getCaptionsPython');

  const videoData = {
    videoId,
    durationInSeconds,
    videoThumbnail: thumbnailUrl,
    videoTitle: title,
    fullUrl,
    isPublic,
    contentId, 
  };

  let is503Error = false;
  let isUnAuthError = false;
  let isInternalError = false;
  let isNotFoundError = false;

  const requestData = {
    videoData,
    isReprocess,
    isPublic,
  };

  // console.log('Payload being sent to getCaptionsPython:', requestData);

  return new Promise((resolve) => {
    getCaptionsPythonCallable(requestData)
      .then((result) => {
        const { message } = result.data;

        resolve({
          message,
          is503Error,
          isUnAuthError,
          isInternalError,
          isNotFoundError,
        });
      })
      .catch((error) => {
        const code = error.code;
        let message = 'An unknown error occurred';

        switch (code) {
          case 'functions/permission-denied':
            isUnAuthError = true;
            message = 'Permission denied';
            break;
          case 'functions/unavailable':
            is503Error = true;
            message = 'Service unavailable';
            break;
          case 'functions/internal':
            isInternalError = true;
            message = 'Internal server error';
            break;
          case 'functions/not_found':
            isNotFoundError = true;
            message = 'Function not found';
            break;
          default:
            message = error.message || message;
        }

        resolve({
          message,
          is503Error,
          isUnAuthError,
          isInternalError,
          isNotFoundError,
        });
      });
  });
};






// FOR /WORKSPACE PAGE -- GRABBING FOR NEW WORKSPACE DATA

export const getYoutubeWORKSPACEcaptions = ({
  videoId,
  durationInSeconds,
  thumbnailUrl,
  title,
  isReprocess,
  fullUrl,
  isPublic,
  content_id = null,
  isWorkspace = false
}) => {
  const functions = getFunctions();
  const getCaptionsPythonCallable = httpsCallable(functions, 'getCaptionsPython');
  const timestamp = Date.now();

  // Construct the video object with conditional inclusion of isPublic
  const videoData = {
    videoId,
    durationInSeconds,
    timestamp,
    videoType: 'youtubeUrl',
    videoThumbnail: thumbnailUrl,
    videoTitle: title,
    fullUrl,
    contentId: content_id ? `workspace-${content_id}` : undefined, // Ensure contentId is correctly formatted
  };

  // Conditionally add isPublic if it exists
  if (typeof isPublic === 'boolean') {
    videoData.isPublic = isPublic;
  }

  // Initialize error flags
  let is503Error = false;
  let isUnAuthError = false;
  let isInternalError = false;
  let isNotFoundError = false;

  const requestData = {
    videoData,
    isReprocess,
    ...(isWorkspace && { isWorkspace: true }),  // Only include isWorkspace if true
    // If isPublic exists, include it in requestData (optional)
    ...(typeof isPublic === 'boolean' && { isPublic }),
  };

  // Debugging: Log the requestData to verify
  // console.log('Calling getCaptionsPython with data:', requestData);

  return new Promise((resolve) => {
    getCaptionsPythonCallable(requestData)
      .then((result) => {
        // Extract the message from the result
        const { message } = result.data;

        // Resolve with the message and error flags
        resolve({
          message, // "success", "captions_not_available", or "captions_not_available_in_language"
          is503Error,
          isUnAuthError,
          isInternalError,
          isNotFoundError,
        });
      })
      .catch((error) => {
        const code = error.code;
        let message = 'An unknown error occurred';

        // Set error flags based on error code
        switch (code) {
          case 'functions/permission-denied':
            isUnAuthError = true;
            message = 'Permission denied';
            break;
          case 'functions/unavailable':
            is503Error = true;
            message = 'Service unavailable';
            break;
          case 'functions/internal':
            isInternalError = true;
            message = 'Internal server error';
            break;
          case 'functions/not_found':
            isNotFoundError = true;
            message = 'Function not found';
            break;
          default:
            message = error.message || message;
        }

        // Debugging: Log the error details
        // console.error(`Error occurred - Code: ${code}, Message: ${error.message}`, error.details);

        // Resolve with the error message and flags
        resolve({
          message, // Error message
          is503Error,
          isUnAuthError,
          isInternalError,
          isNotFoundError,
        });
      });
  });
};







// functions/permission-denied