// src/components/UploadWorkspace/LocalWorkspace.js

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './localWorkspace.css'; // Updated CSS with renamed classes

// FIREBASE
import { deleteObject } from 'firebase/storage';
import { db, auth } from '../../../../firebase';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';

// FUNCTIONS
import { uploadlocalWorkspace } from './uploadlocalWorkspace'; // Ensure this path is correct

// IMPORT UI
import InsufficientTimePopUp from '../../../../components/miscPages/InsufficientTimePopUp'; // Adjust the path as needed

// IMAGES
import uploadLogo from '../../../../assets/images/upload-v1.png';
import bumpPlanLogo from '../../../../assets/images/bumpups-white.png';
import processBumpupslogo from '../../../../assets/gifmedia/bumpups-2.gif';
import placeholderImage from '../../../../assets/images/placeholder-load.jpg';


const LocalWorkspace = ({ content_id, isLOCALProcessing }) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [timeBankBump, setTimeBankBump] = useState(0); // Added state for timeBankBump
    const [showInsufficientTimePopup, setShowInsufficientTimePopup] = useState(false); // State to control InsufficientTimePopUp
    const fileInputRef = useRef(null);
    const uploadTaskRef = useRef(null);
    const storagePathRef = useRef('');

    const MAX_FILE_SIZE_GB = 31;
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_GB * 1024 * 1024 * 1024;

    useEffect(() => {
        const user = auth.currentUser;

        if (user) {
            const userDocRef = doc(db, "users", user.uid);
            const timeWardenRef = doc(userDocRef, "subscriptionData", "timeWarden");

            // Listen for updates to timeBankBump
            const unsubscribeTimeBank = onSnapshot(timeWardenRef, (doc) => {
                if (doc.exists()) {
                    setTimeBankBump(doc.data().timeBankBump); // Set the timeBankBump state
                }
            });

            return () => {
                unsubscribeTimeBank();
            };
        }
    }, []);

    const [videoDurationSec, setVideoDurationSec] = useState(null);

    const handleUpload = useCallback(async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Check if the file size exceeds the maximum allowed size
        if (file.size > MAX_FILE_SIZE_BYTES) {
            alert(`The selected video file exceeds the maximum size of ${MAX_FILE_SIZE_GB} GB. Please select a smaller file.`);
            return;
        }

        // Calculate video duration
        const videoElement = document.createElement('video');
        videoElement.preload = 'metadata';

        videoElement.onloadedmetadata = function() {
            window.URL.revokeObjectURL(videoElement.src);
            const duration = videoElement.duration; // duration is in seconds

            // Save the duration in state
            setVideoDurationSec(duration);

            // Convert timeBankBump from minutes to seconds
            const timeBankBumpInSeconds = timeBankBump * 60;

            // Check if the duration exceeds the timeBankBump
            if (duration > timeBankBumpInSeconds) {
                setShowInsufficientTimePopup(true); // Show the insufficient time popup
                return;
            } else {
                setSelectedFile(file);

                const user = auth.currentUser;
                // console.log("Content ID:", content_id); // Console log for content_id

                uploadlocalWorkspace(
                    file,
                    user,
                    content_id, // Correctly pass content_id
                    setUploadProgress,
                    setShowPopup,
                    storagePathRef,
                    uploadTaskRef,
                );

                // Reset the input field
                event.target.value = null;
            }
        };

        videoElement.src = URL.createObjectURL(file);
    }, [MAX_FILE_SIZE_BYTES, timeBankBump, content_id]);

    useEffect(() => {
        const dropArea = document.querySelector('.localwork-file-drop-area');

        const handleDragOver = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined) return; // Prevent actions if timeBankBump is 0, null, or undefined

            const files = event.dataTransfer.items;
            if (files.length > 0 && files[0].kind === 'file' && files[0].type.startsWith('video/')) {
                dropArea.classList.add('drag-over');
            }
        };

        const handleDragLeave = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined) return; // Prevent actions if timeBankBump is 0, null, or undefined

            dropArea.classList.remove('drag-over');
        };

        const handleDrop = (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined) return; // Prevent actions if timeBankBump is 0, null, or undefined

            dropArea.classList.remove('drag-over');
            const file = event.dataTransfer.files[0];
            if (file) {
                handleUpload({ target: { files: [file] } });
            }
        };

        dropArea.addEventListener('dragover', handleDragOver);
        dropArea.addEventListener('dragleave', handleDragLeave);
        dropArea.addEventListener('drop', handleDrop);

        return () => {
            dropArea.removeEventListener('dragover', handleDragOver);
            dropArea.removeEventListener('dragleave', handleDragLeave);
            dropArea.removeEventListener('drop', handleDrop);
        };
    }, [timeBankBump, handleUpload]); // Add timeBankBump to dependencies

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="localwork-upload-section">
            <div className={`localwork-upload-area ${(timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined) ? 'disabled' : ''}`}>
                <label htmlFor="fileInput" id="fileInputLabel" className="localwork-file-input-label">
                    <div className="localwork-file-drop-area">
                        <input 
                            type="file" 
                            id="fileInput" 
                            ref={fileInputRef}
                            accept="video/mp4,video/mov,video/webm,video/quicktime" 
                            onChange={handleUpload} 
                            hidden 
                            disabled={timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined}
                        />
                        <div className="localwork-upload-circle">
                            <img src={uploadLogo} alt="Upload Logo" className="localwork-upload-logo" />
                        </div>
                        {(timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined) ? (
                            <>
                            <p className="localwork-upload-instruction">
                                You need <Link to="/plans" className="bump-base">Bump</Link> Plan credits for local videos
                            </p>                            
                            <p className="localwork-upload-subtext">Please add Bump credits to enable uploads.</p>
                            </>
                        ) : (
                            <>
                                <p className="localwork-upload-instruction">Drag and drop video files to upload</p>
                                <p className="localwork-upload-subtext">Your videos will be private until you publish them.</p>
                            </>
                        )}
                        {(timeBankBump === 0 || timeBankBump === null || timeBankBump === undefined) ? (
                            <Link 
                                to="/plans" 
                                className="localwork-select-files-button"
                                style={{ textDecoration: 'none' }} // Ensure button is not underlined
                            >
                                Manage Plans
                            </Link>
                        ) : (
                            <button className="localwork-select-files-button" onClick={triggerFileInput}>
                                <img src={bumpPlanLogo} alt="Bump Plan Logo" className="localwork-icon" />
                                Select files
                            </button>
                        )}
                    </div>
                </label>
            </div>

            {/* {showJustUploadedPopup && <JustUploadedPopup />} */}
            {showPopup && <UploadPopup 
                progress={uploadProgress} 
                file={selectedFile} 
                onClose={() => setShowPopup(false)} 
                uploadTaskRef={uploadTaskRef} 
                storagePathRef={storagePathRef} 
                content_id={content_id} // Replaced videoId with content_id
                videoDurationSec={videoDurationSec} 
                timeBankBump={timeBankBump} 
                isLOCALProcessing={isLOCALProcessing} 
            />}

            {showInsufficientTimePopup && <InsufficientTimePopUp />} 
        </div>
   ) };

export default LocalWorkspace;

// Nested Components

function UploadPopup({ progress, file, onClose, uploadTaskRef, storagePathRef, content_id, videoDurationSec, isLOCALProcessing }) {
    const [videoUrl, setVideoUrl] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const user = auth.currentUser;
    const [displayedProgress, setDisplayedProgress] = useState(progress);
    const [uploadStatusText, setUploadStatusText] = useState(`Uploading (${progress}%)`);
    const [profileImage, setProfileImage] = useState(placeholderImage); // Initialize with placeholder

    // Fetch profile image from Firestore
    useEffect(() => {
        const fetchProfileImage = async () => {
            if (!user) return;

            const userId = user.uid;
            const profileDocRef = doc(db, 'users', userId, 'userSettings', 'profile');

            try {
                const profileSnapshot = await getDoc(profileDocRef);

                if (profileSnapshot.exists()) {
                    const profileData = profileSnapshot.data();
                    const fetchedProfileImage = profileData.profileImage || placeholderImage;
                    setProfileImage(fetchedProfileImage);
                    sessionStorage.setItem(`profileImage_${user.uid}`, fetchedProfileImage);
                } else {
                    setProfileImage(placeholderImage);
                }

                // Listen for real-time updates
                const unsubscribeProfile = onSnapshot(profileDocRef, (doc) => {
                    if (doc.exists()) {
                        const data = doc.data();
                        const updatedProfileImage = data.profileImage || placeholderImage;
                        setProfileImage(updatedProfileImage);
                        sessionStorage.setItem(`profileImage_${user.uid}`, updatedProfileImage);
                    } else {
                        setProfileImage(placeholderImage);
                    }
                });

                // Cleanup listener on unmount
                return () => {
                    unsubscribeProfile();
                };
            } catch (error) {
                // console.error('Error fetching profile image:', error);
                setProfileImage(placeholderImage);
            }
        };

        fetchProfileImage();
    }, [user]);

    // Create a URL for the video file
    useEffect(() => {
        const url = URL.createObjectURL(file);
        setVideoUrl(url);

        return () => URL.revokeObjectURL(url);
    }, [file]);

    // Update upload progress and status text
    useEffect(() => {
        if (progress < 98) {
            setDisplayedProgress(progress);
            setUploadStatusText(`Uploading (${progress}%)`);
        } else if (progress >= 98 && progress < 100) {
            setDisplayedProgress(98);
            setUploadStatusText(`Uploading (98%)`);
        } else if (progress === 100) {
            setDisplayedProgress(100);
            setUploadStatusText('Finalizing Upload...');
            
        }
    }, [progress]);

    const [isOutofTime, setIsOutofTime] = useState(false);
    useEffect(() => {
        if (!user) return;

        const userDocRef = doc(db, "users", user.uid);
        const timeWardenRef = doc(userDocRef, "subscriptionData", "timeWarden");

        const unsubscribeTimeBank = onSnapshot(timeWardenRef, (doc) => {
            if (doc.exists()) {
                const timeBankBumpInMinutes = doc.data().timeBankBump;

                // Convert timeBankBump to seconds
                const timeBankBumpInSeconds = timeBankBumpInMinutes * 60;

                // Check if videoDurationSec is more than timeBankBumpInSeconds and progress is under 100
                if (videoDurationSec > timeBankBumpInSeconds && progress < 100) {
                    setIsOutofTime(true);

                    // Cancel the upload if out of time and progress is under 100
                    if (uploadTaskRef.current) {
                        uploadTaskRef.current.cancel();
                        // console.log("Upload canceled due to insufficient credits.");
                    }
                } else {
                    setIsOutofTime(false);
                }
            }
        });

        return () => {
            unsubscribeTimeBank();
        };
    }, [user, videoDurationSec, progress, uploadTaskRef]);


    const handleCancelUpload = () => {
        if (progress === 100 && isLOCALProcessing) { // Both conditions must be true
            localStorage.removeItem(`recentWorkspace_${user.uid}`);
            onClose();
        } else {
            setShowConfirmation(true);
        }
    };

    const [isDiscarding, setIsDiscarding] = useState(false);
    const handleConfirmClose = async () => {
        setIsDiscarding(true);

        if (progress < 100 || !isLOCALProcessing) { // Add check for isLOCALProcessing
            if (uploadTaskRef.current) {
                uploadTaskRef.current.cancel(); // Cancel the video upload task
            }
        }

        if (storagePathRef.current) {
            const videoRef = storagePathRef.current;
            try {
                await deleteObject(videoRef);
                // console.log("Video deleted successfully.");
            } catch (error) {
                // console.error("Error deleting video:", error);
            }
        }

        setIsDiscarding(false);
        setShowConfirmation(false);
        onClose();
    };

    const handleCancelClose = () => {
        setShowConfirmation(false);
    };

    // Handle upload errors
    useEffect(() => {
        const handleError = (error) => {
            setUploadError(true);
            // console.error("Upload error:", error);
        };

        if (uploadTaskRef.current) {
            uploadTaskRef.current.on('state_changed', null, handleError);
        }
    }, [uploadTaskRef]);

    // Warn user before leaving the page if upload is not complete
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (progress < 100 || !isLOCALProcessing) { // Add check for isLOCALProcessing
                event.preventDefault();
                event.returnValue = ''; // This triggers the browser confirmation dialog
            }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [progress, isLOCALProcessing]); // Add isLOCALProcessing as dependency
    
    // Convert video duration in seconds to minutes
    let videoDurationMin = Math.floor(videoDurationSec / 60);
    if (videoDurationSec > 0 && videoDurationSec < 60) {
        videoDurationMin = 1;
    }

    const truncateFileName = (name) => {
        return name.length > 28 ? `${name.slice(0, 28)}...` : name;
    };

    return (
        <div className="localwork-upload-popup">
            <div className="localwork-upload-popup-content">
                <div className="localwork-upload-header">
                    <img src={profileImage} alt="User Profile" className="localwork-prevprofile-image" />
                    <button onClick={handleCancelUpload} className="localwork-upload-close-button">X</button>
                </div>
                <div className="localwork-upload-content-wrapper">
                    <div className="localwork-video-wrapper" style={{ position: 'relative' }}>
                        <video src={videoUrl} controls className="localwork-upload-video" />
                    </div>
                    <div className="localwork-upload-info-wrapper">
                        <div className="localwork-upload-info">
                            <p className="localwork-upload-message">
                                Keep your browser tab open to avoid interruptions. You may cancel anytime but if your video reaches 100% uploaded, your plan credits will be deducted.
                            </p>
                            <a href="https://intercom.help/bumpups/en/articles/9742215-understanding-the-video-upload-process" className="localwork-learn-more-link" target="_blank" rel="noopener noreferrer">Learn more</a>
                        </div>
                    </div>
                    <div className={`localwork-upload-status-wrapper ${uploadError || isOutofTime ? 'error' : ''}`}>
                        <div className="localwork-upload-status">
                            <p className="localwork-upload-progress">
                                <strong>
                                    {truncateFileName(file.name)}: {isOutofTime 
                                        ? 'You are out of credits' 
                                        : (uploadError ? 'Error Uploading -- Retry' : uploadStatusText)
                                    }
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="localwork-divider" />
                <div className="localwork-upload-buttons">
                    {/* New Left Side for Bump Credit Cost */}
                    <div className="localwork-buttons-left">
                        <div className="bump-credit-usage">
                            <span className="bump-base">Bump</span> <span className="credit-cost">Credit Cost: {videoDurationMin}m</span>
                        </div>
                    </div>
                    <div className="localwork-buttons-right">
                        <img
                            src={bumpPlanLogo}
                            alt="Bump Plan"
                            className="localwork-bump-icon"
                        />
                    </div>
                </div>
            </div>
            {showConfirmation && <CancelUploadPopup onConfirm={handleConfirmClose} onCancel={handleCancelClose} isDiscarding={isDiscarding} />}
        </div>
    );
}

function JustUploadedPopup() {
    return (
        <div className="localwork-just-uploaded-popup">
            <div className="localwork-just-uploaded-popup-content">
                <div className="localwork-uploading-wrapper">
                    <img src={processBumpupslogo} alt="Uploading" className="localwork-uploading-logo" />
                    <p className="localwork-uploading-text">Loading...</p>
                </div>
            </div>
        </div>
    );
}

function CancelUploadPopup({ onConfirm, onCancel, isDiscarding }) {
    return (
        <div className="localwork-cancel-upload-popup" onClick={onCancel}>
            <div className="localwork-cancel-upload-popup-content" onClick={(e) => e.stopPropagation()}>
                {!isDiscarding && <h2 className="localwork-cancel-upload-title">Hold that thought</h2>}
                {isDiscarding ? (
                    <div className="localwork-loading-wrapper">
                        <img src={processBumpupslogo} alt="Loading" className="localwork-loading-logo" />
                        <span className="localwork-loading-text">Deleting...</span>
                    </div>
                ) : (
                    <p className="localwork-cancel-upload-message">
                        We are still uploading your video. Are you sure you want to discard your post? Your draft will be lost.
                    </p>
                )}
                {!isDiscarding && (
                    <div className="localwork-cancel-upload-popup-buttons">
                        <button onClick={onCancel} className="localwork-cancel-button">Continue</button>
                        <button onClick={onConfirm} className="localwork-confirm-button">Discard</button>
                    </div>
                )}
            </div>
        </div>
    );
}
