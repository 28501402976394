import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Make sure Firebase is properly imported
import './CloseSidebar.css';

// IMAGES
import selectedIcon from '../assets/images/v2-pricing.png';
import helpIcon from '../assets/images/v2-helpicon.png';
import dashboardLogo from '../assets/images/dashboard-logo-v6.png';
import subicon from '../assets/images/v2-plan.png';
import linkIcon from '../assets/images/link-v3.png';
import bumpUPlogo from '../assets/images/comment-v2.png';
import uploadLogo from '../assets/images/upload-white-v1.png';



function CloseSidebar() {
    // State to track the current view
    const [currentView, setCurrentView] = useState('');


  const initialLanguage = localStorage.getItem(`userLanguageEmoji_${auth.currentUser?.uid}`) || '🇺🇸';
  const [language, setLanguage] = useState(initialLanguage);

    // Effect to track the current view based on window location
    useEffect(() => {
        const updateView = () => {
            setCurrentView(window.location.pathname);
        };

        window.addEventListener('popstate', updateView);
        updateView(); // Set initial state

        return () => window.removeEventListener('popstate', updateView);
    }, []);

    // Effect to fetch language data and set the language state
    useEffect(() => {
        const languages = [
            { value: 'en', label: 'en', name: '🇺🇸' },
            { value: 'hi', label: 'hi', name: '🇮🇳' },
            { value: 'es', label: 'es', name: '🇪🇸' },
            { value: 'pt', label: 'pt', name: '🇵🇹' },
            { value: 'ru', label: 'ru', name: '🇷🇺' },  // Already just emoji
            { value: 'de', label: 'de', name: '🇩🇪' },
            { value: 'fr', label: 'fr', name: '🇫🇷' },
            { value: 'ja', label: 'ja', name: '🇯🇵' },
            { value: 'ko', label: 'ko', name: '🇰🇷' },
            { value: 'ar', label: 'ar', name: '🇸🇦' }
        ];
    
        const user = auth.currentUser;
        if (user) {
            const userDocRef = doc(db, 'users', user.uid, 'userSettings', 'profile');
    
            const fetchUserLanguage = async () => {
                try {
                    const localLanguage = localStorage.getItem(`userLanguageEmoji_${user.uid}`);
                    if (localLanguage) {
                        setLanguage(localLanguage);
                    } else {
                        const userDoc = await getDoc(userDocRef);
                        if (userDoc.exists()) {
                            const userData = userDoc.data();
                            const userLanguage = userData.userDefaultLanguage;
                            const selectedLanguage = languages.find(lang => lang.value === userLanguage);
    
                            if (selectedLanguage) {
                                setLanguage(selectedLanguage.name);  // Use only the emoji
                                localStorage.setItem(`userLanguageEmoji_${user.uid}`, selectedLanguage.name);
                            } else {
                                setLanguage('🇺🇸'); // Default to emoji if no valid language
                                localStorage.setItem(`userLanguageEmoji_${user.uid}`, '🇺🇸');
                            }
                        } else {
                            setLanguage('🇺🇸');  // Default to emoji
                            localStorage.setItem(`userLanguageEmoji_${user.uid}`, '🇺🇸');
                        }
                    }
                } catch (error) {
                    console.error("Error fetching user language: ", error);
                    setLanguage('🇺🇸');  // Default to emoji on error
                }
            };
    
            fetchUserLanguage();
    
            const unsubscribeLanguage = onSnapshot(userDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    const newLanguage = docSnap.data().userDefaultLanguage;
                    const selectedLanguage = languages.find(lang => lang.value === newLanguage);
    
                    if (selectedLanguage) {
                        setLanguage(selectedLanguage.name);  // Use only the emoji
                        localStorage.setItem(`userLanguageEmoji_${user.uid}`, selectedLanguage.name);
                    }
                }
            });
    
            return () => {
                unsubscribeLanguage();
            };
        }
    }, []);
    
    // Helper functions for styling links based on the current view
    const getLinkClass = (view) => {
        return `close-sidebar-link ${isCurrentView(view) ? 'close-link-selected' : ''}`;
    };

    const getIconClass = (view) => {
        return `close-link-icon ${isCurrentView(view) ? 'close-link-icon-selected' : ''}`;
    };

    const isCurrentView = (view) => currentView === view;

    return (
        <div className="close-sidebar">
            <div className="close-create-wrapper">
                <Link to="/workspace" className="close-create-button" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img src={bumpUPlogo} alt="Bump Up Logo" className="link-icon-bump-close" />
                </Link>
            </div>
            <div className="close-dividersides"></div>

            <Link to="/dashboard" className={getLinkClass('/dashboard')}>
                <img src={dashboardLogo} alt="Dashboard" className={getIconClass('/dashboard')} />
            </Link>

            {/* <Link to="/creator" className={getLinkClass('/creator')}>
                <img src={creatoricon} alt="Creator" className={getIconClass('/creator')} />
            </Link> */}

            <Link to="/links" className={getLinkClass('/links')}>
                <img src={linkIcon} alt="Links" className={getIconClass('/links')} />
            </Link>

            <Link to="/bumper" className={getLinkClass('/bumper')}>
                <img src={uploadLogo} alt="Upload" className={getIconClass('/bumper')} />
            </Link>

            <a href="https://intercom.help/bumpups/en/" target="_blank" rel="noopener noreferrer" className={getLinkClass('/help')}>
                <img src={isCurrentView('/help') ? selectedIcon : helpIcon} alt="Help" className={getIconClass('/help')} />
            </a>

            <Link to="/plans" className={getLinkClass('/plans')}>
                <img src={subicon} alt="Plans" className={getIconClass('/plans')} />
            </Link>
            <Link to="/settings" className={getLinkClass('/settings')}>
            <div className="close-language">
                <span className="language-emoji">{language}</span>
            </div>
            </Link>


        </div>
    );
}

export default CloseSidebar;
