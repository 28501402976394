import React, { useEffect, useState } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { doc, getDoc } from 'firebase/firestore';
import { videoStorage, auth, db } from '../../../../firebase';
import './localWorkspace.css';
import LoadingThumbnail from '../../../../assets/images/thumbnailPlacehlder.jpg';
import bumpupsWhite from '../../../../assets/images/bumpups-white.png';

const LocalVideoDisplay = ({
  content_id,
  isLocalTranscriptAvailable,
  isLOCALProcessing,
  isTranscriptEmpty,
}) => {
  const [videoUrl, setVideoUrl] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [videoQuality, setVideoQuality] = useState('');
  const [videoDuration, setVideoDuration] = useState(600); // Default in case no duration is retrieved
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [bumpingTimeLeft, setBumpingTimeLeft] = useState('');

  const user = auth.currentUser;

  // Fetch video URL from Firebase Storage
  useEffect(() => {
    if (!user) return;

    const fetchVideoUrl = async () => {
      const videoRef = ref(videoStorage, `${user.uid}/workspace-${content_id}`);
      try {
        const url = await getDownloadURL(videoRef);
        setVideoUrl(url);
      } catch (error) {}
    };

    fetchVideoUrl();
  }, [user, content_id]);

  // Fetch video metadata and duration from Firestore or localStorage
  useEffect(() => {
    const fetchVideoMetadata = async () => {
      if (!isLocalTranscriptAvailable && !isLOCALProcessing) return;

      const localStorageKey = `videometadata_${content_id}`;
      const cachedMetadata = localStorage.getItem(localStorageKey);

      if (cachedMetadata) {
        try {
          const { video_file_name, video_quality, video_duration_in_seconds } = JSON.parse(cachedMetadata);
          setVideoTitle(video_file_name || '');
          setVideoQuality(video_quality || '');
          setVideoDuration(video_duration_in_seconds || 600); // Default if not found
        } catch (error) {
          localStorage.removeItem(localStorageKey);
          await fetchFromFirestore(localStorageKey);
        }
      } else {
        await fetchFromFirestore(localStorageKey);
      }
    };

    const fetchFromFirestore = async (localStorageKey) => {
      if (!user) return;

      try {
        const userDocRef = doc(db, 'users', user.uid);
        const workspaceDocRef = doc(userDocRef, 'workspaceContent', `workspace-${content_id}`);
        const workspaceDocSnap = await getDoc(workspaceDocRef);

        if (workspaceDocSnap.exists()) {
          const data = workspaceDocSnap.data();
          const metadata = {
            video_file_name: data.video_file_name || '',
            video_quality: data.video_quality || '',
            video_duration_in_seconds: data.video_duration_in_seconds || 600, // Default if not found
          };
          setVideoTitle(metadata.video_file_name);
          setVideoQuality(metadata.video_quality);
          setVideoDuration(metadata.video_duration_in_seconds);
          localStorage.setItem(localStorageKey, JSON.stringify(metadata));
        } else {
          setVideoTitle('');
          setVideoQuality('');
          setVideoDuration(600); // Default if document does not exist
        }
      } catch (error) {
        setVideoTitle('');
        setVideoQuality('');
        setVideoDuration(600); // Default on error
      }
    };

    fetchVideoMetadata();
  }, [user, content_id, isLocalTranscriptAvailable, isLOCALProcessing]);

  // Calculate time left for processing
  useEffect(() => {
    const storageKey = `bumpingTimeLeft_${content_id}`;
  
    // Initialize start time with error handling for localStorage parsing
    let startTime = Date.now();
    try {
      const savedTimeData = localStorage.getItem(storageKey);
      if (savedTimeData) {
        startTime = JSON.parse(savedTimeData).startTime || Date.now();
      }
    } catch (error) {
      console.error("Error parsing startTime from localStorage:", error);
      localStorage.removeItem(storageKey); // Clear corrupted data if parsing fails
    }
  
    const totalProcessingTimeMinutes = videoDuration <= 600 ? 5 : 6;
  
    // Declare interval as a ref to manage scope and access
    let interval;
  
    const calculateBumpingTimeLeft = () => {
      const elapsedTimeMinutes = (Date.now() - startTime) / (1000 * 60);
      const remainingTimeMinutes = totalProcessingTimeMinutes - elapsedTimeMinutes;
  
      if (remainingTimeMinutes > 60) {
        setBumpingTimeLeft(`${Math.ceil(remainingTimeMinutes / 60)} hours left`);
      } else if (remainingTimeMinutes > 1) {
        setBumpingTimeLeft(`${Math.ceil(remainingTimeMinutes)} minutes left`);
      } else if (remainingTimeMinutes > 0) {
        setBumpingTimeLeft('1 minute left');
      } else {
        setBumpingTimeLeft('successfully bumped');
        clearInterval(interval); // Clear interval when countdown is complete
      }
  
      // Update localStorage with current start time and remaining time
      localStorage.setItem(storageKey, JSON.stringify({ startTime, remainingTimeMinutes }));
    };
  
    // Start countdown and set up interval
    calculateBumpingTimeLeft();
    interval = setInterval(calculateBumpingTimeLeft, 60000);
  
    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [content_id, videoDuration]);
  
  const truncateFileName = (name) => {
    return name.length > 28 ? `${name.slice(0, 28)}...` : name;
  };

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className="localworks-video-section">
      <div className="localworks-video-preview">
        {videoUrl ? (
          <>
            {!isVideoLoaded && (
              <img
                src={LoadingThumbnail}
                alt="Video Thumbnail"
                className="localworks-video-preview-placeholder"
              />
            )}
            <video
              src={videoUrl}
              controls
              className="localworks-video-preview-media"
              onLoadedData={handleVideoLoaded}
              style={{ display: isVideoLoaded ? 'block' : 'none' }}
            />
          </>
        ) : (
          <img src={LoadingThumbnail} alt="Video Thumbnail" className="localworks-video-preview-placeholder" />
        )}
      </div>
      <div className="localworks-video-info">  
      {isLOCALProcessing ? (
          <div className="localworks-logo-wrapper">
            <img src={bumpupsWhite} alt="Bumpups Logo" className="localworks-bumpups-logo" />
            <div className="localworks-bumping-time">
              {isTranscriptEmpty ? (
                <>
                  Video uploaded does not meet our{' '}
                  <a
                    href="https://intercom.help/bumpups/en/articles/8835886-what-are-the-video-requirements"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="localwork-learn-more-link"
                  >
                    requirements
                  </a>
                  , Don't worry your Bump credits were reimbursed to your account 🤠
                </>
              ) : (
                bumpingTimeLeft === 'successfully bumped'
                  ? 'Finalizing process...'
                  : `Loading ... ${bumpingTimeLeft}`
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="localworks-filename-container">
              <div className="localworks-filename-label">Filename</div>
              <div className="editBump-section-filename">{truncateFileName(videoTitle)}</div>
            </div>
            <div className="localworks-quality-container">
              <div className="localworks-quality">{videoQuality || ''}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LocalVideoDisplay;
